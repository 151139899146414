import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  makeStyles,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

import api from "utils/api";
import AdditionalRequirements from "./AdditionalRequirements.js";
import ContactDetails from "./ContactDetails.js";
import EnquiryConfirmation from "components/EnquiryConfirmation";
import EventDetails from "./EventDetails.js";
import handleFormValuesToSubmit from "./handleFormValuesToSubmit.js";
import SideBarModal from "components/SideBarModal";
import { handleEnquirySuccessFormData } from "./utils.js";
import getEventFormFields, {
  initialEnquiryContactDetails,
  getAdditionalRequirementsFields,
} from "./EnquiryEventFormFields.js";
import {
  validateRequiredInputs,
  validateContactDetails,
  applyErrorStateToFormFields,
  applyErrorStateToContactFormFields,
} from "utils/formHelpers.js";
import { addDefaults } from "./utils/addDefaults.js";

const useStyles = makeStyles((theme) => ({
  mainContentContainer: {
    width: "100%",
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  detailsCard: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  CTAContainer: {
    display: "flex",
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  stepContainer: {
    padding: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(2)}px`,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      padding: `${theme.spacing(3)}px`,
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  stepper: {
    background: "none",
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  stepperEditIcon: {
    color: theme.palette.primary.main,
    width: "22px",
  },
  stepHeadingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textButton: { paddingLeft: 0, "&:hover": { backgroundColor: "inherit" } },
  textButtonOverride: {
    paddingLeft: 0,
    "&:hover": { backgroundColor: "inherit" },
    marginTop: theme.spacing(2),
    color: theme.palette.text.red,
  },
  nextStepCTA: {
    position: "absolute",
    display: "flex",
    justifyContent: "flex-end",
    right: "0",
    // marginRight: theme.spacing(9),
  },
  inputField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 20000,
      backgroundColor: theme.palette.background.default,
    },
  },
  hidden: {
    display: "none",
  },
  loadingContainer: {
    outline: "solid 1px red",
    textAlign: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  submittedFormContainer: {
    marginBottom: theme.spacing(3),
  },
  enquirySummaryContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  successHeading: {
    color: theme.palette.success.contrastText,
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    padding: "12px 16px",
    backgroundColor: theme.palette.success.main,
  },
  dateContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  finishButton: {
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  validationFailed: {
    backgroundColor: theme.palette.error.background,
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: "4px",
  },
}));

const EnquirySideBarModal = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));
  const desktopUpScreen = useMediaQuery(theme.breakpoints.up("desktop"));

  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [enquiryConfirmation, setEnquiryConfirmation] = useState(null);
  const [termsValidationError, setTermsValidationError] = useState(false);
  const selectedFacilityType = props.facilityType;
  const selectedProperty = props.property;

  const [eventDetails, setEventDetails] = useState({});
  const [enquiryContactDetails, setEnquiryContactDetails] = useState(initialEnquiryContactDetails);
  const [additionalRequirements, setAdditionalRequirements] = useState(
    getAdditionalRequirementsFields(selectedFacilityType)
  );

  useEffect(() => {
    // DEV NOTE: Needs to be in useEffect as modal may load before facilityServices
    setEventDetails(
      getEventFormFields({
        facilityType: selectedFacilityType,
        facilityServices: props.facilityServices,
        selectedRoomId: props.selectedRoomId,
      })
    );

    // Below also in here to clear fields when new enquiry is started (dependent on enquiryConfirmation)
    setEnquiryContactDetails(initialEnquiryContactDetails);
    setAdditionalRequirements(getAdditionalRequirementsFields(selectedFacilityType));
  }, [props.facilityServices, selectedFacilityType, props.selectedRoomId, enquiryConfirmation]);

  const handleAdditionalRequirementsChange = (key, value) => {
    setAdditionalRequirements((prev) => ({ ...prev, [key]: { ...prev[key], value } }));
  };

  const handleEnquiryContactDetailsChange = (key, value) => {
    setEnquiryContactDetails((prevState) => ({
      ...prevState,
      [key]: { ...prevState[key], value },
    }));
  };
  const handleEventDetailsChange = (key, value) => {
    setEventDetails((prevState) => ({ ...prevState, [key]: { ...prevState[key], value } }));
  };

  const toggleTermsAcceptance = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const validateTermsAccepted = () => {
    if (!termsAccepted) {
      props.onNotifOpen("Please accept terms and conditions to continue", {
        variant: "error",
      });

      setTermsValidationError(true);
    }
    return termsAccepted;
  };

  const makeBooking = async (data) => {
    const response = await api.makeEnquiry(data);
    return response.data;
  };

  const handleSubmit = ({
    eventDetails,
    enquiryContactDetails,
    additionalRequirements,
    selectedProperty,
  }) => {
    const data = handleFormValuesToSubmit({
      eventDetails,
      enquiryContactDetails,
      additionalRequirements,
      selectedFacilityType,
      selectedProperty,
    });
    setIsLoading(true);

    return makeBooking(data)
      .then((response) => {
        setIsLoading(false);
        return response;
      })
      .catch((error) => {
        const errorMessage = "Unknown error";
        const message = `Your enquiry could not be completed. ${errorMessage}`;
        setIsLoading(false);
        props.onNotifOpen(message, { variant: "error" });
        return Promise.reject(errorMessage);
      });
  };

  const handleNext = () => {
    if (activeStep === 0) {
      // Event Details step
      const isValid = validateRequiredInputs(eventDetails);
      if (!isValid) {
        // Update errors in eventDetails
        setEventDetails((prevState) => {
          const updatedFormFields = applyErrorStateToFormFields(prevState);
          return updatedFormFields;
        });

        return false;
      } else {
        setActiveStep((prev) => prev + 1);
      }
    }
    // Your details step
    if (activeStep === 1) {
      const isContactDetailsValid = validateContactDetails(enquiryContactDetails);

      if (!isContactDetailsValid) {
        setEnquiryContactDetails((prevState) => {
          const updatedFormFields = applyErrorStateToContactFormFields(prevState);
          return updatedFormFields;
        });
        return false;
      }
      if (!validateTermsAccepted()) {
        return false;
      }

      handleSubmit({
        eventDetails,
        enquiryContactDetails,
        additionalRequirements,
        selectedProperty,
      })
        .then((data) => {
          setEnquiryConfirmation(handleEnquirySuccessFormData(data));
          setHasSubmitted(true);
          setActiveStep((prev) => prev + 1);
        })
        .catch((errorMessage) => {
          return false;
        });
    }
  };

  const enquirySteps = () => {
    const steps = [
      {
        label: "Event Details",
        content: (
          <Paper className={classes.stepContainer}>
            <EventDetails
              eventDetails={addDefaults(eventDetails)}
              handleEventDetailsChange={handleEventDetailsChange}
            />
            <AdditionalRequirements
              additionalRequirements={additionalRequirements}
              handleAdditionalRequirementsChange={handleAdditionalRequirementsChange}
            />
          </Paper>
        ),
      },
      {
        label: "Your Details",
        content: (
          <Paper className={classes.stepContainer}>
            <ContactDetails
              enquiryContactDetails={enquiryContactDetails}
              handleEnquiryContactDetailsChange={handleEnquiryContactDetailsChange}
              termsValidationError={termsValidationError}
              termsAccepted={termsAccepted}
              toggleTermsAcceptance={toggleTermsAcceptance}
            />
          </Paper>
        ),
      },
    ];

    return steps;
  };

  function getStepLabels() {
    return enquirySteps().map((step) => step.label);
  }
  function getStepContent(step) {
    return enquirySteps().map((step) => step.content)[step];
  }

  function handleShowEditIcon({ currentStep, activeStep }) {
    if (currentStep >= activeStep) {
      return false;
    } else {
      return true;
    }
  }

  const steps = getStepLabels();

  const stepTo = (step) => {
    if (step <= activeStep) {
      setActiveStep(step);
    }
  };

  const handleBackClick = () => {
    props.onClose();
    props.handleChooseRoomModalOpen && props.handleChooseRoomModalOpen();
  };

  const pageContents = (
    <>
      {!isLoading && !enquiryConfirmation && (
        <Box className={classes.mainContentContainer}>
          <Box>
            {props.hideBackButton ? null : (
              <Button
                className={classes.textButton}
                color="primary"
                size="small"
                variant="text"
                disableRipple
                onClick={() => handleBackClick()}>
                <Box className={classes.CTAContainer}>
                  <ArrowBack />
                  <Typography variant="subtitle1">Back</Typography>
                </Box>
              </Button>
            )}
            <Box className={classes.detailsCard}>
              <Stepper
                className={classNames(classes.stepper, isLoading ? classes.hidden : null)}
                activeStep={activeStep}
                orientation="vertical">
                {steps.map((label, index) => (
                  <Step
                    style={{
                      position: "relative",
                    }}
                    key={label}
                    active={activeStep === index}>
                    <StepLabel
                      onClick={() => {
                        stepTo(index);
                      }}>
                      <Box className={classes.stepHeadingContainer}>
                        <Typography variant={tabletUpScreen ? "h4" : "h5"}>{label}</Typography>
                        {handleShowEditIcon({ currentStep: index, activeStep }) && (
                          <Button>
                            <CreateOutlinedIcon
                              className={classes.stepperEditIcon}
                              color="primary"
                            />
                          </Button>
                        )}
                      </Box>
                    </StepLabel>
                    <StepContent
                      hidden={activeStep !== index && !desktopUpScreen}
                      style={{ paddingRight: tabletUpScreen ? "inherit" : 0 }}>
                      {getStepContent(index)}
                    </StepContent>
                    {activeStep === index && (
                      <Box className={classes.nextStepCTA} onClick={handleNext}>
                        <Button variant="contained" color="primary" size="large">
                          Next Step
                        </Button>
                      </Box>
                    )}
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        </Box>
      )}
      {(isLoading || hasSubmitted) && (
        <EnquiryConfirmation
          embedded={props.embedded}
          enquiryConfirmation={enquiryConfirmation}
          isLoading={isLoading}
          onClose={props.onClose}
          setHasSubmitted={setHasSubmitted}
          setEventDetails={setEventDetails}
          setEnquiryConfirmation={setEnquiryConfirmation}
          setActiveStep={setActiveStep}
          selectedProperty={selectedProperty}
          facilityName={props.facilityName}
        />
      )}
    </>
  );

  return props.embedded ? (
    pageContents
  ) : (
    <SideBarModal modalTitle="Send enquiry" {...props}>
      {pageContents}
    </SideBarModal>
  );
};

EnquirySideBarModal.propTypes = {
  embedded: PropTypes.bool, // Embedded mode - i.e full page
  hideBackButton: PropTypes.bool, // Used for embedded mode
  property: PropTypes.object,
  facilityType: PropTypes.string,
  facilityName: PropTypes.string,
  facilityServices: PropTypes.array,
  selectedRoomId: PropTypes.number,
  onClose: PropTypes.func,
  onNotifOpen: PropTypes.func,
  handleChooseRoomModalOpen: PropTypes.func,
};

export default EnquirySideBarModal;
