import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Routes from "config/routes";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { FastfoodOutlined, KingBedOutlined } from "@material-ui/icons";

import api from "utils/api";
import { useSearchParams } from "hooks";
import { isKioskFromSearchParams } from "utils/helpers";
import { getSelectedProperty } from "../../selectors/selectedProperty";
import Heading from "components/Heading";
import Navbar from "components/Navbar";
import KioskConfirmStay from "components/KioskConfirmStay";
import DonationWidget from "components/DonationWidget";
import LoadingSpinner from "components/LoadingSpinner";

// Sections
import SectionWithHeader from "./SectionWithHeader";
import GetMolliesApp from "components/GetMolliesApp";
import ContactUsSection from "components/ContactUsSection";
import SelectDiningTimes from "components/SelectDiningTimes";
import StayBookingSummary from "components/StayBookingSummary";
import DiningBookingSummary from "components/DiningBookingSummary";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    flexDirection: "column",
    margin: "1rem auto",
    width: theme.contentSize.mobilePageWidth,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: theme.contentSize.pageWidth,
      maxWidth: theme.contentSize.maxPageWidth,
    },
  },
  loadingSpinner: {
    display: "grid",
    padding: "3rem 0",
  },
}));

const ConfirmStay = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const desktopUpScreen = useMediaQuery(theme.breakpoints.up("desktop"));
  const searchParams = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [diningVenue, setDiningVenue] = useState(null);

  const hasDiningReservations = props.diningReservations.length > 0;

  // Show the donation modal if enabled for this property, and payment card is eligible
  const [donationStepComplete, setDonationStepComplete] = useState(false);
  const donationsEnabled =
    props.selectedProperty?.booking_flow_settings?.donation_config?.donations_enabled || false;
  const paymentEligibleForDonations = !!(props.donation?.token || false);

  const eligibleForDonation =
    donationsEnabled && paymentEligibleForDonations && !donationStepComplete;

  useEffect(() => {
    if (props.bookingConfirmationId == null) {
      props.history.push(Routes.PLAN_STAY);
    }

    // Fetch the targeted values
    // then, perform two state updates
    api.getRestaurantVenues(props.propertyId).then((response) => {
      const venues = response.data || [];

      // For now, we will only support a single restautrant venue.
      // The API supports multiple, but the front-end will only support 1
      // If mollies have multiple locations in future, we can change ui to support, but
      // may need additional logic such as which outlet supports which meal period.
      setDiningVenue(venues[0]);

      setLoading(false);
    });
  }, []);

  // Widgets for the confirm stay page

  const donationWidget = () => {
    return eligibleForDonation ? (
      <Grid item>
        <DonationWidget
          onNotifOpen={props.onNotifOpen}
          setDonationStepComplete={setDonationStepComplete}
        />
      </Grid>
    ) : null;
  };

  const getMolliesAppWidget = () => {
    return (
      <Grid item>
        <GetMolliesApp />
      </Grid>
    );
  };

  const stayBookingSummary = () => {
    return (
      <Grid item>
        <SectionWithHeader title="Stay booked!" icon={<KingBedOutlined />} color="success">
          <StayBookingSummary
            bookingConfirmationId={props.bookingConfirmationId}
            onNotifOpen={props.onNotifOpen}
          />
        </SectionWithHeader>
      </Grid>
    );
  };

  const selectDiningTimesWidget = () => {
    return (
      <Grid item>
        <SectionWithHeader title="Book dining?" icon={<FastfoodOutlined />} color="default">
          <SelectDiningTimes onNotifOpen={props.onNotifOpen} diningVenue={diningVenue} />
        </SectionWithHeader>
      </Grid>
    );
  };

  const diningBookingSummary = () => {
    return (
      <Grid item>
        <SectionWithHeader title="Dining booked!" icon={<FastfoodOutlined />} color="success">
          <DiningBookingSummary
            restaurantVenue={diningVenue}
            reservations={props.diningReservations}
          />
        </SectionWithHeader>
      </Grid>
    );
  };

  // Returns the book dining or dining summary widget
  const diningWidget = () => {
    return hasDiningReservations ? diningBookingSummary() : selectDiningTimesWidget();
  };

  const contactUsWidget = () => {
    return (
      <Grid item>
        <ContactUsSection
          phoneNumber={props.selectedProperty?.contact_info?.phone_number}
          emailAddress={props.selectedProperty?.contact_info?.email_address}
        />
      </Grid>
    );
  };

  return !isKioskFromSearchParams(searchParams) ? (
    <div className={classes.root}>
      <Navbar withProfile onNotifOpen={props.onNotifOpen} />
      <Grid container className={classes.mainContent}>
        <Heading titleText="Reservation confirmed" />
        {loading ? (
          <div className={classes.loadingSpinner}>
            <LoadingSpinner loading={loading} size={70} />
          </div>
        ) : (
          <Grid container spacing={3} direction={desktopUpScreen ? "row" : "column"}>
            {diningVenue ? (
              /* Show dining section */
              /* Column 1: Booking confirmation, Donation, Get Mollie's App */
              /* Column 2: Book Dining / dining confirmation, Contact Us */
              <>
                <Grid item xs={desktopUpScreen ? 4 : null}>
                  <Grid container spacing={3} direction="column" wrap="nowrap">
                    {stayBookingSummary()}
                    {!desktopUpScreen && diningWidget()}
                    {donationWidget()}
                    {desktopUpScreen && getMolliesAppWidget()}
                  </Grid>
                </Grid>
                <Grid item xs={desktopUpScreen ? 8 : null}>
                  <Grid container spacing={3} direction="column" wrap="nowrap">
                    {desktopUpScreen && diningWidget()}
                    {!desktopUpScreen && getMolliesAppWidget()}
                    {contactUsWidget()}
                  </Grid>
                </Grid>
              </>
            ) : (
              /* No dining section */
              /* Column 1: Booking confirmation */
              /* Column 2: Donation, Get Mollies App, Contact Us */
              <>
                <Grid item xs={desktopUpScreen ? 8 : null}>
                  <Grid container spacing={3} direction="column">
                    {stayBookingSummary()}
                    {desktopUpScreen && contactUsWidget()}
                  </Grid>
                </Grid>
                <Grid item xs={desktopUpScreen ? 4 : null}>
                  <Grid container spacing={3} direction="column">
                    {donationWidget()}
                    {getMolliesAppWidget()}
                    {!desktopUpScreen && contactUsWidget()}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  ) : (
    <KioskConfirmStay />
  );
};

ConfirmStay.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onNotifOpen: PropTypes.func,

  // Mapped properties
  propertyId: PropTypes.string,
  selectedProperty: PropTypes.object,
  donation: PropTypes.object,
  bookingConfirmationId: PropTypes.string,
  diningReservations: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => {
  return {
    propertyId: state.booking.commonSearchParams.propertyId,
    selectedProperty: getSelectedProperty(state),
    donation: state.payment?.donation,
    bookingConfirmationId: state.booking.bookingConfirmationId,
    diningReservations: state.dining.diningBookingsResponse || [],
  };
};

const ConnectedConfirmStay = connect(mapStateToProps)(ConfirmStay);
export default ConnectedConfirmStay;
