import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Button,
  Drawer,
  Divider,
  TextField,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import EditIcon from "@material-ui/icons/Edit";

import { useSearchParams } from "hooks";
import { isKioskFromSearchParams } from "utils/helpers";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Navbar from "components/Navbar";
import Heading from "components/Heading";
import FindBookingModal from "components/FindBookingModal";
import FindBookingBox from "components/FindBookingBox";
import MolliesModal from "components/MolliesModal";
import BookingReservationsCard from "components/BookingReservationsCard";
import { fetchProfile, setFirebaseToken, setProfile } from "features/user/userSlice";
import { setRoomDetails } from "features/room/roomSlice";
import api from "utils/api";
import { sendVerificationEmail, reloadUser } from "utils/firebase/firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inherit",
  },
  contentRow: {
    width: "90%",
    margin: "auto",
    [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
      width: "80%",
      maxWidth: "1300px",
    },
  },
  reservationForm: {
    justifyContent: "center",
  },
  reservationDetails: {
    margin: "20px 300px",
  },
  reservationActions: {
    margin: "20px 300px",
  },
  drawerPaper: {
    position: "static",
    background: "transparent",
    borderRight: "none",
  },
  drawerSelected: {
    background: theme.palette.text.primary,
    color: theme.palette.text.onDark.primary,
    borderBottom: "2px solid " + theme.palette.primary.main,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      borderBottom: "none",
    },
  },
  drawerItem: {
    cursor: "pointer",
    padding: "1rem",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      flexDirection: "row",
    },
  },
  drawerList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: "0",
    marginBottom: "1rem",
    flexWrap: "nowrap",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      flexDirection: "column",
      paddingTop: "8px",
      maxWidth: "300px",
    },
  },
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    rowGap: "1rem",
  },
  detailsHeader: {
    margin: "3rem 0",
    height: "30px",
  },
  editIcon: {
    marginRight: "10px",
  },
  detailsContainer: {
    width: "90%",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: "50%",
    },
  },
}));

const Account = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet));
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.user.authUser);
  const profile = useSelector((state) => state.user.profile);
  const firebaseToken = useSelector((state) => state.user.firebaseToken);
  const searchParams = useSearchParams();

  const [groupedBookings, setGroupedBookings] = useState({});
  const [showDetails, setShowDetails] = useState(props.showDetails);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editDetails, setEditDetails] = useState(false);
  const [details, setDetails] = useState();
  const [findBookingModalOpen, setFindBookingModalOpen] = useState(false);

  const setReservationsGroupedByBooking = () => {
    let groupedBookings = {};
    profile.apaleoReservations.forEach((reservation) => {
      if (groupedBookings[reservation.bookingId]) {
        groupedBookings[reservation.bookingId] = [
          ...groupedBookings[reservation.bookingId],
          reservation,
        ];
      } else {
        groupedBookings[reservation.bookingId] = [reservation];
      }
    });
    setGroupedBookings(groupedBookings);
  };

  const fetchLatestReservationsOnProfile = async (wait) => {
    if (authUser && firebaseToken) {
      if (wait) {
        await dispatch(fetchProfile(firebaseToken));
        setLoading(false);
      } else {
        dispatch(fetchProfile(firebaseToken));
      }
    }
  };

  const confirmEmailVerification = async () => {
    const response = await reloadUser();
    dispatch(setFirebaseToken(response.token));

    if (!response.verified) {
      props.onNotifOpen(
        "Please click the verify link in the email we sent you to continue.\nIf you haven't received an email, check your junk folder.",
        { variant: "error" }
      );
    } else {
      props.onNotifOpen("Email verified successfully!", { variant: "success" });
      setOpenConfirmationModal(false);
    }
  };

  const handleDetailsChange = (event) => {
    setDetails({
      ...details,
      [event.target.name]: event.target.value,
    });
  };

  const handleDetailsSubmit = () => {
    api
      .updateProfile(firebaseToken, {
        first_name: details.firstName,
        last_name: details.lastName,
      })
      .then((res) => {
        dispatch(
          setProfile({
            ...profile,
            firstName: details.firstName,
            lastName: details.lastName,
          })
        );
        props.onNotifOpen("Profile updated successfully!", {
          variant: "success",
        });
      })
      .catch(() => {
        props.onNotifOpen("Something went wrong.", {
          variant: "error",
        });
      });
  };

  const handleReservationCancelled = () => {
    fetchLatestReservationsOnProfile(false);
  };

  useEffect(() => {
    if (!authUser) props.redirectAuth();
  }, [authUser]);

  useEffect(() => {
    fetchLatestReservationsOnProfile(true);
  }, [firebaseToken]);

  useEffect(() => {
    if (profile && profile.apaleoReservations) {
      setReservationsGroupedByBooking();
    }
  }, [profile]);

  useEffect(() => {
    setOpenConfirmationModal(authUser && !authUser.emailVerified);
  }, [authUser]);

  useEffect(() => {
    api.getRoomDetails().then((res) => {
      dispatch(setRoomDetails(res.data));
    });
  }, []);

  useEffect(() => {
    setDetails({
      firstName: profile?.firstName,
      lastName: profile?.lastName,
    });
  }, [profile]);

  return (
    <div className={classes.root}>
      {!isKioskFromSearchParams(searchParams) && (
        <Navbar
          withProfile
          onProfileChange={props.onProfileChange}
          onNotifOpen={props.onNotifOpen}
        />
      )}
      <FindBookingModal
        open={findBookingModalOpen}
        setOpen={setFindBookingModalOpen}
        onNotifOpen={props.onNotifOpen}
      />
      <Grid container className={classes.contentRow}>
        <Box mt={4}>
          <Grid container justifyContent="flex-start" alignItems="center" hidden={!tabletUpScreen}>
            <Typography variant="body2" color="textSecondary">
              My account
            </Typography>
            <Box mx={2}>
              <Typography variant="body2" color="textSecondary">
                /
              </Typography>
            </Box>
            <Typography variant="body2" color="textPrimary">
              My {showDetails ? "details" : "bookings"}
            </Typography>
          </Grid>
        </Box>
        <Heading titleText="My account" responsive={"tablet"} />
        <div>
          <MolliesModal
            open={openConfirmationModal}
            title="Please verify your account to continue"
            buttons={[
              <Button
                key="verification"
                variant="outlined"
                color="primary"
                onClick={() => {
                  sendVerificationEmail(props.onNotifOpen);
                }}>
                Resend verification email
              </Button>,
              <Button
                key="continue"
                variant="contained"
                color="primary"
                onClick={confirmEmailVerification}>
                Continue
              </Button>,
            ]}
          />
        </div>
        <Grid container direction={tabletUpScreen ? "row" : "column"}>
          {authUser ? (
            <Drawer
              variant="permanent"
              anchor="left"
              PaperProps={{ className: classes.drawerPaper }}>
              {tabletUpScreen ? (
                <Box mb={2}>
                  <Typography variant="h6" color="textPrimary">
                    Hello {authUser.displayName.split("|")[0]}
                  </Typography>
                </Box>
              ) : null}
              {tabletUpScreen ? <Divider /> : null}
              <Grid
                container
                direction={tabletUpScreen ? "row" : "column"}
                className={classes.drawerList}>
                <Button
                  onClick={() => {
                    setShowDetails(!showDetails);
                  }}
                  style={{ padding: 0 }}>
                  <Grid
                    container
                    className={`${classes.drawerItem} ${
                      showDetails ? classes.drawerSelected : null
                    }`}>
                    <PersonOutlineOutlinedIcon />
                    <Box mr={tabletUpScreen ? 6 : 3} ml={tabletUpScreen ? 2 : 3} my={1}>
                      <Typography variant="subtitle2">My details</Typography>
                    </Box>
                  </Grid>
                </Button>
                <Button
                  onClick={() => {
                    setShowDetails(!showDetails);
                  }}
                  style={{ padding: 0 }}>
                  <Grid
                    container
                    className={`${classes.drawerItem} ${
                      showDetails ? null : classes.drawerSelected
                    }`}>
                    <CalendarTodayIcon />
                    <Box mr={tabletUpScreen ? 6 : 3} ml={tabletUpScreen ? 2 : 3} my={1}>
                      <Typography variant="subtitle2">My bookings</Typography>
                    </Box>
                  </Grid>
                </Button>
                {tabletUpScreen ? (
                  <FindBookingBox hidden={showDetails} setOpen={setFindBookingModalOpen} />
                ) : null}
              </Grid>
            </Drawer>
          ) : null}
          {showDetails ? (
            <Box className={classes.detailsContainer} m="auto">
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
                className={classes.detailsHeader}>
                <Typography variant="h5" color="textPrimary">
                  Personal details
                </Typography>
                {!editDetails ? (
                  <Box alignSelf="flex-start">
                    <Button
                      color="primary"
                      onClick={() => {
                        setEditDetails(true);
                      }}>
                      <EditIcon className={classes.editIcon} />
                      Edit
                    </Button>
                  </Box>
                ) : null}
              </Grid>

              <div className={classes.formContainer}>
                <Typography variant="subtitle2" color="textPrimary">
                  Email
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {profile?.email}
                </Typography>

                <Typography variant="subtitle2" color="textPrimary">
                  First name
                </Typography>
                {editDetails ? (
                  <TextField
                    value={details.firstName}
                    name="firstName"
                    onChange={handleDetailsChange}
                  />
                ) : (
                  <Typography variant="body2" color="textPrimary">
                    {profile?.firstName}
                  </Typography>
                )}

                <Typography variant="subtitle2" color="textPrimary">
                  Last name
                </Typography>
                {editDetails ? (
                  <TextField
                    value={details.lastName}
                    name="lastName"
                    onChange={handleDetailsChange}
                  />
                ) : (
                  <Typography variant="body2" color="textPrimary">
                    {profile?.lastName}
                  </Typography>
                )}
              </div>
              {editDetails ? (
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Box mr={1}>
                    <Button
                      onClick={() => {
                        setEditDetails(false);
                      }}
                      variant="outlined"
                      color="primary">
                      Cancel
                    </Button>
                  </Box>
                  <Button onClick={handleDetailsSubmit} variant="contained" color="primary">
                    Save changes
                  </Button>
                </Box>
              ) : null}
            </Box>
          ) : (
            <Box m="auto">
              {loading ? <LoadingSpinner loading={loading} /> : null}
              {Object.keys(groupedBookings).length === 0 && !loading ? (
                <Typography variant="body2" color="textPrimary" align="center">
                  No reservations.
                </Typography>
              ) : null}
              {!loading
                ? Object.keys(groupedBookings).map((bookingId, i) => (
                    <BookingReservationsCard
                      key={i}
                      bookingReservations={groupedBookings[bookingId]}
                      onReservationCancelled={handleReservationCancelled}
                      onNotifOpen={props.onNotifOpen}
                    />
                  ))
                : null}
              {tabletUpScreen ? null : (
                <Box mb={4}>
                  <FindBookingBox hidden={showDetails} setOpen={setFindBookingModalOpen} />
                </Box>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

Account.propTypes = {
  showDetails: PropTypes.bool,
  onNotifOpen: PropTypes.func,
  redirectAuth: PropTypes.func,
  onProfileChange: PropTypes.func,
};

export default Account;
