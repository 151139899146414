import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useSignInWithFirebaseToken } from "hooks";
import { useSearchParams } from "hooks";
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";

import api from "utils/api";
import EnquirySideBarModal from "components/EnquirySideBarModal";
import FacilityBookingSideBarModal from "components/FacilityBookingSideBarModal";
import queryString from "query-string";
import LoadingSpinner from "components/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  facilityPageRoot: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      // To accommodate fixed button at bottom of page on mobile
      marginBottom: theme.spacing(10),
    },
  },
}));

const EmbeddedFacilityBookingPage = (props) => {
  // Restore the booking confirmation if available. It was base64 encoded, and json stringified
  const queryParams = queryString.parse(window.location.search);
  const bookingConfirmation = queryParams.bookingConfirmationString
    ? JSON.parse(atob(queryParams.bookingConfirmationString))
    : null;
  const classes = useStyles();
  const searchParams = useSearchParams();
  const params = useParams();
  useSignInWithFirebaseToken(searchParams.fbauthtoken);
  const [isLoading, setIsLoading] = useState(true);
  const [enquiryMode, setEnquiryMode] = useState(false);
  // In the case we redirected from a successful open the modal to show success message.
  const [facility, setFacility] = useState({});
  const [facilityServices, setFacilityServices] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState(null);

  const properties = useSelector((state) => state.property.properties);
  const property = properties.find((property) => property.slug === params.propertySlug);

  // Enquiry Side Bar Modal Open
  const handleEnquirySideBarModalOpen = () => {
    setEnquiryMode(true);
  };

  const handleCloseEnquiryPage = () => {
    setEnquiryMode(false);
  };
  // END//

  useEffect(() => {
    if (!property) return;

    setEnquiryMode(props.launchInEnquiryMode);

    let apiFacilities = api.getFacility(property.id, params.facilitySlug);

    apiFacilities
      .then((response) => {
        const res = get(response, ["data", 0]);
        setFacility(res);
        return res;
      })
      .then((facility) =>
        api.getFacilityServices(facility?.id).then((response) => {
          const res = get(response, ["data"]);
          setFacilityServices(res);
          setSelectedRoomId(parseInt(params.facilityServiceId));

          setIsLoading(false);
        })
      );
  }, [params, property]);

  return (
    <div className={classes.facilityPageRoot}>
      {isLoading ? (
        <>
          <LoadingSpinner loading={isLoading} size={128} />
        </>
      ) : (
        <>
          {enquiryMode ? (
            <EnquirySideBarModal
              embedded
              open
              hideBackButton={props.launchInEnquiryMode}
              onClose={handleCloseEnquiryPage}
              onNotifOpen={props.onNotifOpen}
              facilityServices={facilityServices}
              facilityType={facility?.type}
              facilityName={facility?.name}
              property={property}
              selectedRoomId={selectedRoomId}
            />
          ) : (
            <FacilityBookingSideBarModal
              embedded
              open
              onNotifOpen={props.onNotifOpen}
              facilityServices={facilityServices}
              facility={facility}
              property={property}
              selectedRoomId={selectedRoomId}
              setSelectedRoomId={setSelectedRoomId}
              handleEnquirySideBarModalOpen={handleEnquirySideBarModalOpen}
              bookingConfirmation={bookingConfirmation}
            />
          )}
        </>
      )}
    </div>
  );
};

EmbeddedFacilityBookingPage.propTypes = {
  launchInEnquiryMode: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onNotifOpen: PropTypes.func,
};

export default EmbeddedFacilityBookingPage;
