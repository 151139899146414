import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  makeStyles,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { omit, pick } from "lodash/fp";

import AdditionalRequirements from "./AdditionalRequirements.js";
import CheckFacilityAvailability from "components/CheckFacilityAvailability";
import ContactDetails from "./ContactDetails.js";
import EventDetails from "./EventDetails.js";
import FacilityBookingConfirmation from "components/FacilityBookingConfirmation";
import getEventFormFields, {
  initialAdditionalRequirements,
  initialBookingContactDetails,
} from "./BookingEventFormFields.js";
import handleFormValuesToSubmit from "./handleFormValuesToSubmit.js";
import SideBarModal from "components/SideBarModal";
import StripePaymentWidget from "components/StripePaymentWidget";
import {
  CHECK_AVAILABILITY_STEP_INDEX,
  EVENT_DETAILS_STEP_INDEX,
  PAYMENT_DETAILS_STEP_INDEX,
  YOUR_DETAILS_STEP_INDEX,
  getSelectedRoomData,
  handleAvailabilitySummary,
} from "./utils.js";
import moment from "moment";
import {
  validateRequiredInputs,
  validateContactDetails,
  applyErrorStateToFormFields,
  applyErrorStateToContactFormFields,
} from "utils/formHelpers.js";
import CustomizedStepIcon from "./CustomizedStepIcon.js";
import { getOptions } from "./utils/getOptions.js";
import { createUnpaidReservation } from "./utils/createUnpaidReservation.js";
import { buildBookingConfirmationUrl } from "./utils/buildBookingConfirmationUrl.js";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner.js";

const useStyles = makeStyles((theme) => ({
  mainContentContainer: {
    width: "100%",
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  detailsCard: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  CTAContainer: {
    display: "flex",
    gap: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    alignItems: "center",
  },
  stepContainer: {
    padding: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(2)}px`,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      padding: `${theme.spacing(3)}px`,
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  stepContainerOverride: {
    padding: 0,
    paddingTop: theme.spacing(3),
  },
  stepper: {
    background: "none",
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  stepperEditIcon: {
    color: theme.palette.primary.main,
    width: "22px",
  },
  stepHeadingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textButton: { paddingLeft: 0, "&:hover": { backgroundColor: "inherit" } },
  textButtonOverride: {
    paddingLeft: 0,
    "&:hover": { backgroundColor: "inherit" },
    marginTop: theme.spacing(2),
    color: theme.palette.text.red,
  },
  nextStepCTA: {
    position: "absolute",
    display: "flex",
    justifyContent: "flex-end",
    right: "0",
  },
  inputField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 20000,
      backgroundColor: theme.palette.background.default,
    },
  },
  loadingContainer: {
    outline: "solid 1px red",
    textAlign: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  submittedFormContainer: {
    marginBottom: theme.spacing(3),
  },
  enquirySummaryContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  successHeading: {
    color: theme.palette.success.contrastText,
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    padding: "12px 16px",
    backgroundColor: theme.palette.success.main,
  },
  dateContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  finishButton: {
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  validationFailed: {
    backgroundColor: theme.palette.error.background,
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: "4px",
  },
}));

const FacilityBookingSideBarModal = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));
  const desktopUpScreen = useMediaQuery(theme.breakpoints.up("desktop"));

  const [activeStep, setActiveStep] = useState(
    props.bookingConfirmation ? PAYMENT_DETAILS_STEP_INDEX + 1 : CHECK_AVAILABILITY_STEP_INDEX
  );
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsValidationError, setTermsValidationError] = useState(false);
  const selectedProperty = props.property;

  const [eventDetails, setEventDetails] = useState({});
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [detailsToBook, setDetailsToBook] = useState(null);

  const [additionalRequirements, setAdditionalRequirements] = useState(
    initialAdditionalRequirements
  );

  const handleEnquiry = () => {
    props.handleEnquirySideBarModalOpen();
  };

  const [bookingContactDetails, setBookingContactDetails] = useState(initialBookingContactDetails);

  const selectedRoom = getSelectedRoomData(props.selectedRoomId, props.facilityServices);

  const restartBooking = () => {
    setBookingContactDetails(initialBookingContactDetails);
    setAdditionalRequirements(initialAdditionalRequirements);
    setActiveStep(0);
  };

  useEffect(() => {
    // DEV NOTE: Needs to be in useEffect as modal may load before facilityServices
    setEventDetails(
      getEventFormFields({
        facility: props.facility,
        facilityServices: props.facilityServices,
        selectedRoomId: props.selectedRoomId,
      })
    );

    restartBooking();
  }, [props.facilityServices, props.facility, props.selectedRoomId]);

  const handleAdditionalRequirementsChange = (key, value) => {
    setAdditionalRequirements((prev) => ({ ...prev, [key]: { ...prev[key], value } }));
  };

  const handleBookingContactDetailsChange = (key, value) => {
    setBookingContactDetails((prevState) => ({
      ...prevState,
      [key]: { ...prevState[key], value },
    }));
  };
  const handleEventDetailsChange = (key, value) => {
    setEventDetails((prevState) => ({ ...prevState, [key]: { ...prevState[key], value } }));
  };

  const toggleTermsAcceptance = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const validateTermsAccepted = () => {
    if (!termsAccepted) {
      props.onNotifOpen("Please accept terms and conditions to continue", {
        variant: "error",
      });

      setTermsValidationError(true);
    }
    return termsAccepted;
  };

  const addDefaults = (formFields) => {
    // NM: Start on today.
    const defaultDate = moment();

    Object.entries(formFields).forEach(([formFieldKey, formFieldValues]) => {
      if (formFieldValues.value) return;

      const options = getOptions(formFieldValues, defaultDate);
      switch (formFieldKey) {
        case "arrivalDate":
          formFields[formFieldKey].value = defaultDate;
          break;
        case "arrivalTime":
          if (!options.hide) {
            formFields[formFieldKey].value = options.inputProps.options[0].value;
          }
          break;
      }
    });

    return formFields;
  };

  const handleRemoveFieldsFromPrevStep = (formFields) => {
    return omit(["arrivalDate", "arrivalTime", "numberOfHours", "numberOfPeople", "typeOfRoom"])(
      formFields
    );
  };
  const handlePickFieldsForAvailabilitySearch = (formFields) => {
    return pick(["arrivalDate", "arrivalTime", "numberOfHours", "numberOfPeople"])(formFields);
  };

  const handleDetailsToBook = ({
    eventDetails,
    bookingContactDetails,
    additionalRequirements,
    selectedRoom,
    selectedTimeSlot,
  }) => {
    const data = handleFormValuesToSubmit({
      eventDetails,
      bookingContactDetails,
      additionalRequirements,
      selectedFacilityType: props.facility?.type,
      selectedRoom,
      selectedTimeSlot,
    });

    setDetailsToBook(data);
  };

  const handleNext = () => {
    if (activeStep === CHECK_AVAILABILITY_STEP_INDEX) {
      const hasValidSearchDetails = validateRequiredInputs(
        handlePickFieldsForAvailabilitySearch(eventDetails)
      );

      if (!hasValidSearchDetails) {
        // Update errors in eventDetails
        setEventDetails((prevState) => {
          const updatedFormFields = {
            ...prevState,
            ...applyErrorStateToFormFields(handlePickFieldsForAvailabilitySearch(prevState)),
          };
          return updatedFormFields;
        });

        return false;
      }

      return setActiveStep((prev) => prev + 1);
    }

    if (activeStep === EVENT_DETAILS_STEP_INDEX) {
      const isValid = validateRequiredInputs(eventDetails);
      if (!isValid) {
        // Update errors in eventDetails
        setEventDetails((prevState) => {
          const updatedFormFields = applyErrorStateToFormFields(prevState);
          return updatedFormFields;
        });

        return false;
      } else {
        setActiveStep((prev) => prev + 1);
      }
    }

    if (activeStep === YOUR_DETAILS_STEP_INDEX) {
      const isContactDetailsValid = validateContactDetails(bookingContactDetails);

      if (!isContactDetailsValid) {
        setBookingContactDetails((prevState) => {
          const updatedFormFields = applyErrorStateToContactFormFields(prevState);
          return updatedFormFields;
        });
        return false;
      }
      if (!validateTermsAccepted()) {
        return false;
      }

      handleDetailsToBook({
        eventDetails,
        bookingContactDetails,
        additionalRequirements,
        selectedRoom,
        selectedTimeSlot,
      });

      if (shouldPay()) {
        setActiveStep(PAYMENT_DETAILS_STEP_INDEX);
      } else {
        setIsLoading(true);
        createUnpaidReservation({
          selectedTimeSlot,
          selectedRoom,
          bookingContactDetails,
          additionalRequirements,
        })
          .then(() => {
            setIsLoading(false);
            window.location.replace(
              buildBookingConfirmationUrl({
                eventDetails,
                selectedRoom,
                selectedTimeSlot,
                additionalRequirements,
              })
            );
          })
          .catch((error) => {
            const errorMessage = error?.response?.data?.error?.message || "Unknown error";
            const message = `Your reservation could not be completed. ${errorMessage}`;
            setIsLoading(false);
            props.onNotifOpen(message, { variant: "error" });
            return Promise.reject(errorMessage);
          });
      }
    }
  };

  const handleError = (error) => {
    const apiErrorMessage = error?.response?.data?.error?.message;
    props.onNotifOpen(apiErrorMessage || "Something went wrong", {
      variant: "error",
    });
  };

  // NM: Under certain conditions users don't have to pay the booking.
  const shouldPay = () => selectedTimeSlot == null || selectedTimeSlot.cost != null;

  const bookingSteps = () => {
    const steps = [
      {
        label: "Check availability",
        content: (
          <Paper className={classNames(classes.stepContainer, classes.stepContainerOverride)}>
            <CheckFacilityAvailability
              searchDetails={addDefaults(handlePickFieldsForAvailabilitySearch(eventDetails))}
              selectedRoom={selectedRoom}
              selectedProperty={selectedProperty}
              selectedTimeSlot={selectedTimeSlot}
              setSelectedTimeSlot={(newSelectedTimeSlot) => {
                setSelectedTimeSlot(newSelectedTimeSlot);

                // NM: If we have a new time slot directly travel
                // to next step.
                if (newSelectedTimeSlot != null) handleNext();
              }}
              handleSearchDetailsChange={handleEventDetailsChange}
              handleEnquiry={handleEnquiry}
              validateSearchDetails={() => {
                const isValid = validateRequiredInputs(
                  handlePickFieldsForAvailabilitySearch(eventDetails)
                );

                if (isValid) return true;

                // Update errors in eventDetails
                setEventDetails((prevState) => {
                  const updatedFormFields = {
                    ...prevState,
                    ...applyErrorStateToFormFields(
                      handlePickFieldsForAvailabilitySearch(prevState)
                    ),
                  };
                  return updatedFormFields;
                });

                return false;
              }}
              handleError={handleError}
            />
          </Paper>
        ),
      },
      {
        label: "Event details",
        content: (
          <Paper className={classes.stepContainer}>
            <EventDetails
              eventDetails={handleRemoveFieldsFromPrevStep(eventDetails)}
              handleEventDetailsChange={handleEventDetailsChange}
            />
            <AdditionalRequirements
              additionalRequirements={additionalRequirements}
              handleAdditionalRequirementsChange={handleAdditionalRequirementsChange}
            />
          </Paper>
        ),
      },
      {
        label: "Your details",
        content: (
          <Paper className={classes.stepContainer}>
            <ContactDetails
              bookingContactDetails={bookingContactDetails}
              handleBookingContactDetailsChange={handleBookingContactDetailsChange}
              termsValidationError={termsValidationError}
              termsAccepted={termsAccepted}
              toggleTermsAcceptance={toggleTermsAcceptance}
            />
          </Paper>
        ),
      },
      ...(shouldPay()
        ? [
            {
              label: "Payment details",
              content: (
                <Paper className={classes.stepContainer}>
                  <StripePaymentWidget
                    buildBookingConfirmationUrl={() =>
                      buildBookingConfirmationUrl({
                        eventDetails,
                        selectedRoom,
                        selectedTimeSlot,
                        additionalRequirements,
                      })
                    }
                    propertyCode={selectedProperty?.code}
                    detailsToBook={detailsToBook}
                    handleError={handleError}
                    cost={selectedTimeSlot?.cost}
                  />
                </Paper>
              ),
            },
          ]
        : []),
    ];

    return steps;
  };

  function getStepLabels() {
    return bookingSteps().map((step) => step.label);
  }
  function getStepContent(step) {
    return bookingSteps().map((step) => step.content)[step];
  }

  function handleShowEditIcon({ currentStep, activeStep }) {
    if (currentStep >= activeStep) {
      return false;
    } else {
      return true;
    }
  }

  const [steps, setSteps] = useState([]);
  useEffect(() => {
    setSteps(getStepLabels());
  }, [selectedTimeSlot]);

  const stepTo = (step) => {
    if (step <= activeStep) {
      setActiveStep(step);
    }
  };

  const handleChangeRoom = () => {
    props.onClose();
    props.handleChooseRoomModalOpen();
  };

  const [isLoading, setIsLoading] = useState(false);

  const pageContents = (
    <>
      {isLoading ? (
        <Box style={{ width: "100%", height: "100%" }} className={classes.loadingContainer}>
          <Box>
            <LoadingSpinner loading={isLoading} />
          </Box>
          <Typography variant="subtitle1" color="textPrimary">
            Sending your reservation...
          </Typography>
        </Box>
      ) : null}
      {!props.bookingConfirmation && (
        <Box className={classes.mainContentContainer}>
          <Box>
            <Box className={classes.CTAContainer}>
              <Typography variant="subtitle1">{selectedRoom?.title}</Typography>
              {props.embedded ? null : (
                <Button
                  className={classes.textButton}
                  color="primary"
                  size="small"
                  variant="text"
                  disableRipple
                  onClick={() => handleChangeRoom()}>
                  <Typography variant="subtitle1">Change</Typography>
                </Button>
              )}
            </Box>
            <Box className={classes.detailsCard}>
              <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <Step
                    style={{
                      position: "relative",
                    }}
                    key={label}
                    active={activeStep === index}>
                    <StepLabel
                      StepIconComponent={CustomizedStepIcon}
                      onClick={() => {
                        stepTo(index);
                      }}>
                      <Box className={classes.stepHeadingContainer}>
                        <div>
                          <Typography variant={tabletUpScreen ? "h4" : "h5"}>{label}</Typography>
                          {activeStep > CHECK_AVAILABILITY_STEP_INDEX &&
                          index == CHECK_AVAILABILITY_STEP_INDEX ? (
                            <Typography variant="body2" color="textSecondary">
                              {handleAvailabilitySummary({
                                eventDetails,
                                selectedTimeSlot,
                                showPrice: !!selectedTimeSlot.cost,
                              })}
                            </Typography>
                          ) : null}
                        </div>
                        {handleShowEditIcon({ currentStep: index, activeStep }) && (
                          <Button>
                            <CreateOutlinedIcon
                              className={classes.stepperEditIcon}
                              color="primary"
                            />
                          </Button>
                        )}
                      </Box>
                    </StepLabel>
                    <StepContent
                      hidden={activeStep !== index && !desktopUpScreen}
                      style={{ paddingRight: tabletUpScreen ? "inherit" : 0 }}>
                      {getStepContent(index)}
                    </StepContent>
                    {activeStep === index &&
                      (index != CHECK_AVAILABILITY_STEP_INDEX || !!selectedTimeSlot) &&
                      index != PAYMENT_DETAILS_STEP_INDEX && (
                        <Box className={classes.nextStepCTA} onClick={handleNext}>
                          <Button variant="contained" color="primary" size="large">
                            {index == YOUR_DETAILS_STEP_INDEX && !shouldPay()
                              ? "Book"
                              : "Next Step"}
                          </Button>
                        </Box>
                      )}
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        </Box>
      )}

      {props.bookingConfirmation && (
        <FacilityBookingConfirmation
          embedded={props.embedded}
          facilityName={props.facility?.name}
          onClose={() => {
            restartBooking();
            props.onClose();
          }}
          selectedProperty={selectedProperty}
          bookingConfirmation={props.bookingConfirmation}
        />
      )}
    </>
  );

  return props.embedded ? (
    pageContents
  ) : (
    <SideBarModal modalTitle="Book room" {...props}>
      {pageContents}
    </SideBarModal>
  );
};

FacilityBookingSideBarModal.propTypes = {
  embedded: PropTypes.bool, // Embedded mode - i.e full page
  property: PropTypes.object,
  facilityServices: PropTypes.array,
  selectedRoomId: PropTypes.number,
  onClose: PropTypes.func,
  onNotifOpen: PropTypes.func,
  handleEnquirySideBarModalOpen: PropTypes.func,
  handleChooseRoomModalOpen: PropTypes.func,
  setSelectedRoomId: PropTypes.func,
  bookingConfirmation: PropTypes.object,
  facility: PropTypes.object,
};

export default FacilityBookingSideBarModal;
