import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/fp/isEmpty";
import getOr from "lodash/fp/getOr";
import classNames from "classnames";

import Routes from "config/routes";
import { Button, Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";

import Navbar from "components/Navbar";
import Heading from "components/Heading";
import AddOnCard from "components/AddOnCard";
import LoadingSpinner from "components/LoadingSpinner";
import StepBreadcrumb from "components/StepBreadcrumb";
import MarkdownPopover from "components/MarkdownPopover";
import Basket from "components/Basket";
import BookingSummary from "components/BookingSummary";

// Child components
import FooterNavBar from "./children/FooterNavBar";

import { useSearchParams } from "hooks";
import {
  addParkingReservationToBooking,
  addServiceToRoomReservation,
  changeServiceCountForRoomReservation,
  removeParkingReservationFromBooking,
  removeServiceFromRoomReservation,
} from "features/booking/bookingSlice";
import api from "utils/api";
import {
  childrenCount,
  getChooseRoomQueryString,
  isKioskFromSearchParams,
  nightCount,
} from "utils/helpers";
import {
  trackAddReservationToCartEvent,
  trackRemoveReservationFromCartEvent,
  trackAddServiceToCartEvent,
  trackRemoveServiceFromCartEvent,
} from "utils/analytics";
import { selectAvailCheckParams } from "selectors/booking";
import { STEPS, EVPARKING_SERVICE_CODE } from "utils/constants";
import { mapServicesForAddon } from "utils/pms_services/mappers";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    flexDirection: "column",
    margin: "1rem auto",
    width: theme.contentSize.mobilePageWidth,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: theme.contentSize.pageWidth,
      maxWidth: theme.contentSize.maxPageWidth,
    },
  },
  addOnItem: {
    marginBottom: "20px",
  },
  changeSearchButton: {
    padding: "3px 8px",
  },
  splitContent: {
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      flexDirection: "row",
    },
  },
  detailsContainer: {
    flexGrow: 1,
  },
  hidden: {
    display: "none",
  },
  bookingSummary: {
    marginBottom: theme.spacing(2),
  },
  basket: {
    marginLeft: "20px",
    flexShrink: 0,
  },
}));

const SelectAddOns = (props) => {
  const classes = useStyles();
  const { data } = props.location;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedPropertyId = useSelector((state) => state.property.selectedPropertyId);
  const roomReservations = useSelector((state) => state.booking.roomReservations);
  const parkingReservations = useSelector((state) => state.booking.parkingReservations);
  const roomOfRooms = getOr(1, ["state", "roomOfRooms"], location);
  const roomsOccupancy = useSelector((state) => state.booking.roomsOccupancy);
  const availCheckParams = useSelector(selectAvailCheckParams(roomOfRooms));
  const searchParams = useSearchParams();
  const queryParams = isEmpty(availCheckParams) ? data.queryParams : availCheckParams;

  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));

  const [pmsServices, setPmsServices] = useState([]);
  const [mappedAddons, setMappedAddons] = useState([]);
  const [parkingAvailability, setParkingAvailability] = useState({});
  const [addParking, setAddParking] = useState(false);
  const [loading, setLoading] = useState(true);

  const [markdownTitle, setMarkdownTitle] = useState("");
  const [markdownText, setMarkdownText] = useState("");
  const [markdownPopoverOpen, setMarkdownPopoverOpen] = useState(false);

  const reservationIdx = roomOfRooms - 1;

  // Returns the number of times this service exists in the basket
  // If a date is passed, we return the count for that date
  const serviceCountInBasket = (serviceId, date = null) => {
    // For services that support date selection, we add each date separately
    // to the basket, so we need to filter on date as well to find it
    const service = roomReservations[reservationIdx].services.find((s) => {
      const containsDate = date ? s.dates.some((d) => d.serviceDate === date) : true;
      return s.service.id === serviceId && containsDate;
    });

    return service?.count || 0;
  };

  const handleAddServices = (serviceSummaries) => {
    const serviceIds = serviceSummaries.map((s) => s.serviceId);

    // Special case for EV parking
    if (serviceIds.some((id) => id === EVPARKING_SERVICE_CODE)) {
      handleToggleAddParkingReservation();
      return;
    }

    // Add the services to the basket for this reservation
    serviceSummaries.forEach((s) => {
      const serviceToAdd = {
        ...getServiceFromId(s.serviceId), // Get the underlying pms service
        ...s,
      };

      dispatch(
        addServiceToRoomReservation({
          reservationIdx: reservationIdx,
          service: serviceToAdd,
          count: s.count,
        })
      );

      trackAddServiceToCartEvent(serviceToAdd, s.count);
    });
  };

  // Changes the count for a specific service
  const handleChangeServiceCount = (
    serviceId,
    count,
    maxCount,
    cmsTitle,
    additionalInfo,
    date = null
  ) => {
    // For services that support date selection, we add each date separately
    // to the basket, so we need to filter on date as well to find it
    const service = roomReservations[reservationIdx].services.find((s) => {
      const containsDate = date ? s.dates.some((d) => d.serviceDate === date) : true;
      return s.service.id === serviceId && containsDate;
    });

    if (service === undefined) {
      // Service no longer in basket - add again
      const s = {
        serviceId,
        count,
        maxCount,
        cmsTitle,
        additionalInfo,
      };

      if (date) {
        s.date = date;
        s.dates = [{ serviceDate: date }];
      }

      handleAddServices([s]);
      return;
    }

    if (count === 0) {
      // Remove from basket
      handleRemoveServices([serviceId], date);
      return;
    }

    dispatch(
      changeServiceCountForRoomReservation({
        reservationIdx,
        serviceId,
        count,
        date,
      })
    );

    const serviceCopy = Object.assign({}, service);
    if (serviceCopy) {
      if (serviceCopy.count > count) {
        trackRemoveServiceFromCartEvent(serviceCopy, serviceCopy.count - count);
      } else {
        trackAddServiceToCartEvent(serviceCopy, count - serviceCopy.count);
      }
    }
  };

  // If date is passed, it only removes services for that date
  const handleRemoveServices = (serviceIds, date = null) => {
    // Special case for EV parking
    if (serviceIds.some((id) => id === EVPARKING_SERVICE_CODE)) {
      handleToggleAddParkingReservation();
      return;
    }

    // Remove the service from the basket for this reservation
    serviceIds.forEach((serviceId) => {
      const serviceToRemove = roomReservations[reservationIdx].services.find(
        (s) => s.service.id === serviceId
      );

      if (serviceToRemove) {
        trackRemoveServiceFromCartEvent(serviceToRemove, serviceToRemove.count);
      }

      dispatch(
        removeServiceFromRoomReservation({
          reservationIdx: reservationIdx,
          serviceId: serviceId,
          date: date,
        })
      );
    });
  };

  const handleOpenMarkdownModal = (title, text) => {
    setMarkdownTitle(title);
    setMarkdownText(text);
    setMarkdownPopoverOpen(true);
  };

  const handleBack = () => history.goBack();

  const handleContinue = () => {
    if (roomOfRooms === roomsOccupancy.length) {
      history.push({
        pathname: Routes.CHECKOUT,
      });
    } else {
      history.push({
        pathname: Routes.CHOOSE_ROOM,
        search: getChooseRoomQueryString(availCheckParams, roomsOccupancy, roomOfRooms),
        state: {
          roomOfRooms: roomOfRooms + 1,
        },
      });
    }
  };

  const getServiceFromId = (serviceId) => {
    return pmsServices.find((e) => e.service.id === serviceId);
  };

  const handleToggleAddParkingReservation = () => {
    const shouldAdd = !addParking;

    if (shouldAdd) {
      // Need to add EV Parking reservation separately since it's a separate reservation from the room
      dispatch(
        addParkingReservationToBooking({
          reservation: parkingAvailability,
          roomOfRooms,
        })
      );

      trackAddReservationToCartEvent(parkingAvailability);
    } else {
      // EV parking will be last reservation in basket at this point
      const reservationIdx = parkingReservations.length - 1;
      dispatch(removeParkingReservationFromBooking(reservationIdx));

      trackRemoveReservationFromCartEvent(parkingAvailability);
    }

    setAddParking(shouldAdd);
  };

  useEffect(() => {
    if (queryParams.ratePlanId) {
      // Get Services of room based on avail check queryParams
      const pmsAddonsRequest = api.getServices(queryParams);

      // Get booking addons from cms
      const cmsAddonsRequest = api.getReservationAddons(selectedPropertyId);

      // Lookup EV parking availability
      const evParkingRequest = api.getAvailabilities({
        propertyId: queryParams.propertyId,
        arrival: queryParams.arrival,
        departure: queryParams.departure,
        timeSliceTemplate: "OverNight",
        unitGroupTypes: "ParkingLot",
        adults: 1,
      });

      Promise.all([pmsAddonsRequest, cmsAddonsRequest, evParkingRequest])
        .then((responses) => {
          const pmsServices = responses[0].data.services;
          const cmsAddons = responses[1].data;
          const evParking = responses[2].data.offers || [];

          const numberOfNights = nightCount(availCheckParams);
          const numberOfAdults = parseInt(availCheckParams.adults);
          const numberOfChildren = childrenCount(availCheckParams);

          // Iterate the addons and associate the related pms service
          // Also adds ev charging as a special case
          const mappedAddons = cmsAddons
            .map((addon) =>
              mapServicesForAddon(
                addon,
                pmsServices,
                evParking[0],
                numberOfNights,
                numberOfAdults,
                numberOfChildren
              )
            )
            .filter((a) => !!a); // Remove invalid addons

          setPmsServices(pmsServices);
          setParkingAvailability(evParking[0]);
          setMappedAddons(mappedAddons);

          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          props.onNotifOpen(error.message, { variant: "error" });
        });
    }
  }, [roomOfRooms]);

  const newAddonCard = (addon, idx) => {
    return (
      <AddOnCard
        className={classes.addOnItem}
        onAddServices={handleAddServices}
        onRemoveServices={handleRemoveServices}
        onUpdateServiceCount={handleChangeServiceCount}
        onOpenMarkdownModel={handleOpenMarkdownModal}
        addon={addon}
        serviceCountInBasket={serviceCountInBasket}
        adultCount={parseInt(availCheckParams.adults)}
        childCount={childrenCount(availCheckParams)}
        checkInDate={availCheckParams.arrival}
        checkOutDate={availCheckParams.departure}
        key={idx}
        index={idx}
      />
    );
  };

  return (
    <div>
      {!isKioskFromSearchParams(searchParams) && (
        <Navbar withProfile onNotifOpen={props.onNotifOpen} />
      )}
      <Grid container className={classes.mainContent}>
        <StepBreadcrumb currentStep={STEPS.CUSTOMIZE_STAY.INDEX} />
        <Heading
          titleText={`${STEPS.CUSTOMIZE_STAY.PAGE_TITLE} ${
            roomsOccupancy.length > 1 ? `(Room ${roomOfRooms} of ${roomsOccupancy.length})` : ""
          }`}>
          {tabletUpScreen && (
            <Button color="primary" endIcon={<ArrowForward />} onClick={handleContinue}>
              Skip
            </Button>
          )}
        </Heading>
        <LoadingSpinner loading={loading} />
        <Grid
          container
          className={classNames(classes.splitContent, loading ? classes.hidden : null)}>
          <Grid className={classes.detailsContainer}>
            {/* Booking Summary (mobile only) */}
            {tabletUpScreen ? null : (
              <BookingSummary className={classes.bookingSummary} onNotifOpen={props.onNotifOpen} />
            )}

            {/* Addon Cards */}
            {mappedAddons.map(newAddonCard)}

            {/* Navigation */}
            <FooterNavBar onBack={handleBack} onContinue={handleContinue} />
          </Grid>

          {/* Basket (desktop only) */}
          {tabletUpScreen && <Basket className={classes.basket} onNotifOpen={props.onNotifOpen} />}
        </Grid>
      </Grid>
      <MarkdownPopover
        open={markdownPopoverOpen}
        onClose={() => setMarkdownPopoverOpen(false)}
        title={markdownTitle}
        markdown={markdownText}
      />
    </div>
  );
};

SelectAddOns.propTypes = {
  location: PropTypes.object,
  onNotifOpen: PropTypes.func,
};

export default SelectAddOns;
