import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Box,
  Button,
  Divider,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import { removeQueryParams } from "./utils/removeQueryParams";

const useStyles = makeStyles((theme) => ({
  mainContentContainer: {
    width: "100%",
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  stepContainer: {
    padding: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(2)}px`,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      padding: `${theme.spacing(3)}px`,
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  textButton: { paddingLeft: 0, "&:hover": { backgroundColor: "inherit" } },
  textButtonOverride: {
    paddingLeft: 0,
    "&:hover": { backgroundColor: "inherit" },
    marginTop: theme.spacing(1.5),
    color: theme.palette.text.red,
  },
  submittedFormContainer: {
    marginBottom: theme.spacing(3),
  },
  enquirySummaryContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  successHeading: {
    color: theme.palette.success.contrastText,
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    padding: "12px 16px",
    backgroundColor: theme.palette.success.main,
  },
  formFieldsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(0.5),
    gap: theme.spacing(2),
  },
  finishButton: {
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
}));

const FacilityBookingConfirmation = ({
  onClose,
  bookingConfirmation,
  selectedProperty,
  facilityName,
  embedded,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    setShowDetails(false);
  }, [bookingConfirmation]);

  const moreDetailsSortOrder = [
    "selectedRoom",
    "nameOfEvent",
    "eventType",
    "companyName",
    "numberOfPeople",
    ...(bookingConfirmation.cost.value ? ["cost"] : []),
    "specialRequests",
  ];

  return (
    <>
      {bookingConfirmation && (
        <Box style={{ width: "100%", height: "100%" }}>
          {
            <Box
              className={classNames(classes.mainContentContainer, classes.submittedFormContainer)}>
              <Paper>
                <Box className={classes.successHeading}>
                  <ThumbUpAltIcon />
                  <Typography variant="h5">Booking confirmed!</Typography>
                </Box>
                <Box className={classes.stepContainer}>
                  <Box>
                    Thank you for booking with us! A confirmation email has been sent to you.
                  </Box>
                  <Divider className={classes.divider} />
                  <Box className={classes.enquirySummaryContainer}>
                    <Box>
                      <Typography variant="subtitle1">{selectedProperty?.name}</Typography>
                      <Typography
                        variant={
                          tabletUpScreen ? "h5" : "h6"
                        }>{`Mollie's ${facilityName}`}</Typography>
                    </Box>
                    <Box className={classes.formFieldsContainer}>
                      <Typography variant="subtitle2">Date of Booking</Typography>
                      <Typography>
                        {bookingConfirmation["arrivalDate"]?.value},{" "}
                        {bookingConfirmation["start_time"]?.value} -
                        {bookingConfirmation["end_time"]?.value}
                      </Typography>
                    </Box>
                  </Box>
                  {showDetails && (
                    <Box>
                      {moreDetailsSortOrder.map((field, index) => {
                        return (
                          <Box key={index} className={classes.formFieldsContainer}>
                            <Typography variant="subtitle2">
                              {bookingConfirmation[field]?.label}
                            </Typography>
                            <Typography style={{ textAlign: "end" }}>
                              {bookingConfirmation[field]?.value}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                  <Button
                    className={classes.textButtonOverride}
                    aria-label={showDetails ? "Hide details" : "Show details"}
                    variant="text"
                    disableRipple
                    onClick={() => setShowDetails(!showDetails)}
                    endIcon={showDetails ? <ExpandLess /> : <ExpandMore />}>
                    {showDetails ? "Hide details" : "Show details"}
                  </Button>
                </Box>
              </Paper>
              {embedded ? null : (
                <Box className={classes.finishButton}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      removeQueryParams([
                        "bookingConfirmationString",
                        "payment_intent",
                        "payment_intent_client_secret",
                        "redirect_status",
                      ]);
                      onClose();
                    }}
                    size={tabletUpScreen ? "large" : "medium"}>
                    Finish and carry on browsing
                  </Button>
                </Box>
              )}
            </Box>
          }
        </Box>
      )}
    </>
  );
};

FacilityBookingConfirmation.propTypes = {
  embedded: PropTypes.bool, // Embedded mode - i.e full page
  onClose: PropTypes.func,
  setEventDetails: PropTypes.func,
  bookingConfirmation: PropTypes.object,
  selectedProperty: PropTypes.object,
  facilityName: PropTypes.string,
};

export default FacilityBookingConfirmation;
