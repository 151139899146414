import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  Box,
  TextField,
  FormControlLabel,
  Typography,
  useTheme,
  makeStyles,
  Checkbox,
  Link,
} from "@material-ui/core";

import PhoneInput from "components/PhoneInput";

const useStyles = makeStyles((theme) => ({
  containerHeading: {
    marginBottom: theme.spacing(2),
  },
  inputField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 20000,
      backgroundColor: theme.palette.background.default,
    },
  },
  phoneInputField: {
    borderRadius: 20000,
    backgroundColor: theme.palette.background.default,
  },
}));

const ContactDetails = ({
  bookingContactDetails,
  handleBookingContactDetailsChange,
  termsValidationError,
  termsAccepted,
  toggleTermsAcceptance,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Box>
        <Typography variant="subtitle1" color="textPrimary">
          Enter your details
        </Typography>
        <Typography variant="body1" color="textSecondary">
          * required information
        </Typography>
      </Box>
      <Box
        style={{
          marginTop: theme.spacing(2),
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}>
        <TextField
          fullWidth
          name="given-name"
          label="First name"
          value={bookingContactDetails?.firstName?.value}
          required={bookingContactDetails?.firstName?.isRequired}
          error={bookingContactDetails?.firstName?.error}
          helperText={bookingContactDetails?.firstName?.error ? "This field is required." : ""}
          size="small"
          variant="outlined"
          autoComplete="given-name"
          className={classes.inputField}
          InputLabelProps={{
            classes: {
              shrink: classes.shrink,
            },
          }}
          onChange={(evt) => {
            handleBookingContactDetailsChange("firstName", evt.target.value);
          }}
        />
        <TextField
          fullWidth
          name="family-name"
          label="Last name"
          size="small"
          value={bookingContactDetails?.lastName?.value}
          required={bookingContactDetails?.lastName?.isRequired}
          error={bookingContactDetails?.lastName?.error}
          helperText={bookingContactDetails?.lastName?.error ? "This field is required." : ""}
          variant="outlined"
          autoComplete="family-name"
          className={classes.inputField}
          InputLabelProps={{
            classes: {
              shrink: classes.shrink,
            },
          }}
          onChange={(evt) => {
            handleBookingContactDetailsChange("lastName", evt.target.value);
          }}
        />
        <TextField
          fullWidth
          name="email"
          label="Email"
          size="small"
          value={bookingContactDetails?.email?.value}
          variant="outlined"
          autoComplete="given-name"
          required={bookingContactDetails?.email?.isRequired}
          error={bookingContactDetails?.email?.error}
          helperText={
            bookingContactDetails?.email?.error ? "Please enter a valid email address." : ""
          }
          className={classes.inputField}
          InputLabelProps={{
            classes: {
              shrink: classes.shrink,
            },
          }}
          onChange={(evt) => {
            handleBookingContactDetailsChange("email", evt.target.value);
          }}
        />
        <Box>
          <PhoneInput
            defaultCountry="GB"
            placeholder="e.g +44 7911 123456"
            countryOptionsOrder={["GB", "|"]}
            value={bookingContactDetails?.phoneNumber?.value}
            required={bookingContactDetails?.phoneNumber?.isRequired}
            error={bookingContactDetails?.phoneNumber?.error}
            errorHelperText={
              bookingContactDetails?.phoneNumber?.error ? "Please enter a valid phone number" : ""
            }
            className={classes.phoneInputField}
            isV2
            onChange={(value) => {
              handleBookingContactDetailsChange("phoneNumber", value);
            }}
          />
        </Box>
        <Box>
          <FormControlLabel
            className={classNames(
              classes.termsBox,
              termsValidationError ? classes.validationFailed : null
            )}
            control={
              <Checkbox
                className={classes.checkbox}
                checked={termsAccepted}
                onChange={toggleTermsAcceptance}
                color="primary"
              />
            }
            label={
              <Typography variant="body1" color="textPrimary">
                I have read and confirmed the&#8287;
                <Link href="https://www.molliesmotel.com/privacy-booking-policies/" target="_blank">
                  Terms & conditions
                </Link>
              </Typography>
            }
          />
          <FormControlLabel
            className={classes.termsBox}
            control={
              <Checkbox
                className={classes.checkbox}
                checked={bookingContactDetails?.subscribeOffers?.value}
                onChange={(e) =>
                  handleBookingContactDetailsChange("subscribeOffers", e.target.checked)
                }
                color="primary"
              />
            }
            label={
              <Typography variant="body1" color="textPrimary">
                I would like to receive exclusive offers and updates via email from Mollie&apos;s
              </Typography>
            }
          />
        </Box>
      </Box>
    </>
  );
};

ContactDetails.propTypes = {
  bookingContactDetails: PropTypes.object,
  handleBookingContactDetailsChange: PropTypes.func,
  termsValidationError: PropTypes.bool,
  termsAccepted: PropTypes.bool,
  toggleTermsAcceptance: PropTypes.func,
};

export default ContactDetails;
