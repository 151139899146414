import flow from "lodash/fp/flow";
import map from "lodash/fp/map";
import set from "lodash/fp/set";
import find from "lodash/find";

import { facilityType } from "./facilityServicesConstants";
import { toCamelCase } from "./utils/toCamelCase";

export const initialBookingContactDetails = {
  firstName: { value: "", isRequired: true },
  lastName: { value: "", isRequired: true },
  email: { value: "", isRequired: true },
  phoneNumber: { value: "", isRequired: true },
  subscribeOffers: { value: false },
};

export const initialAdditionalRequirements = {
  specialRequests: {
    value: "",
    label: "Special Requests?",
    type: "textField",
  },
};

const formFields = {
  nameOfEvent: {
    name: "nameOfEvent",
    label: "Event Name",
    isRequired: true,
    type: "textField",
    options: {},
    value: "",
  },
  arrivalDate: {
    name: "arrivalDate",
    label: "Date",
    isRequired: true,
    type: "date",
    format: "EEE, d MMM",
    options: {},
    inputLabelProps: {
      shrink: true,
    },
    value: "",
  },
  arrivalTime: {
    name: "arrivalTime",
    label: "Time",
    isRequired: true,
    type: "dropDown",
    options: {},
  },
  numberOfPeople: {
    name: "numberOfPeople",
    label: "Number of attendees",
    isRequired: true,
    type: "number",
    options: {
      inputProps: {
        min: "1",
        max: "12",
      },
    },
    value: "",
  },
  numberOfHours: {
    name: "numberOfHours",
    label: "Duration (hours)",
    isRequired: false,
    type: "number",
    value: "4",
    options: {},
  },
  eventType: {
    name: "eventType",
    label: "Event Type",
    isRequired: true,
    type: "dropDown",
    options: {
      inputProps: {
        options: [
          {
            value: "corporate",
            label: "Corporate",
          },
          {
            value: "party",
            label: "Party",
          },
        ],
      },
    },
    value: "",
  },
  companyName: {
    name: "companyName",
    label: "Company name",
    isRequired: false,
    type: "textField",
    value: "",
    options: {},
  },
};

function findEventFormFields(facility) {
  var todayOptions = facility.metadata.find(
    (metadataItem) => metadataItem.key == "SAME_DAY_BOOKING_CONFIG"
  )?.value;
  const options = facility.metadata.find(
    (metadataItem) => metadataItem.key == "ADVANCED_BOOKING_CONFIG"
  )?.value;

  if (todayOptions) {
    formFields.numberOfHours.todayOptions = {
      hide: todayOptions.duration_range == null,
      inputProps: {
        min: todayOptions.duration_range?.start,
        max: todayOptions.duration_range?.end,
      },
    };

    formFields.arrivalTime.todayOptions = {
      hide: todayOptions.time_options == null,
      inputProps: {
        options:
          todayOptions.time_options == null
            ? []
            : todayOptions.time_options.map((option) => {
                return {
                  value: toCamelCase(option.name || ""),
                  label: option.name,
                  ...option,
                };
              }),
      },
    };
  }

  formFields.numberOfHours.options = {
    hide: options.duration_range == null,
    inputProps: {
      min: options.duration_range?.start,
      max: options.duration_range?.end,
    },
  };

  formFields.arrivalTime.options = {
    hide: options.time_options == null,
    inputProps: {
      options:
        options.time_options == null
          ? []
          : options.time_options.map((option) => {
              return {
                value: toCamelCase(option.name || ""),
                label: option.name,
                ...option,
              };
            }),
    },
  };

  return formFields;
}

const setRoomOptions = (formFields, facilityServices) => {
  const optionsPath = "typeOfRoom.inputProps.options";
  const updatedOptions = map((room) => ({
    value: room.title,
    label: room.title,
  }))(facilityServices);

  return set(optionsPath, updatedOptions, formFields);
};

const setRoomLabel = (formFields) => {
  const optionsPath = "typeOfRoom.label";
  const updatedLabel = "Room";

  return set(optionsPath, updatedLabel, formFields);
};

const setSelectedRoom = (formFields, facilityServices, roomId) => {
  if (roomId) {
    const selectedRoom = find(facilityServices, { id: roomId });
    const optionsPath = "typeOfRoom.value";
    return set(optionsPath, selectedRoom.title, formFields);
  } else {
    return formFields;
  }
};

const getEventFormFields = ({ facility, facilityServices, selectedRoomId = null }) =>
  flow(
    (facility) => findEventFormFields(facility),
    // Set typeOfRoom options with facilityServices
    (formFields) => setRoomOptions(formFields, facilityServices),
    (formFields) => setRoomLabel(formFields),
    // Update typeOfRoom value to the selectedRoom the user is enquiring about
    (formFields) => setSelectedRoom(formFields, facilityServices, selectedRoomId)
  )(facility);

export default getEventFormFields;
