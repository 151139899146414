import { formatDateMedium, formatDateTo24HrTime } from "utils/timekeep";
import moment from "moment";
import { formatCurrency } from "utils/helpers";

const buildBookingConfirmation = ({
  eventDetails,
  selectedTimeSlot,
  selectedRoom,
  additionalRequirements,
}) => {
  return {
    arrivalDate: {
      label: "Date",
      value: formatDateMedium(moment.utc(eventDetails.arrivalDate.value)),
    },
    selectedRoom: { label: "Space", value: selectedRoom.title },
    start_time: {
      label: "Start Time",
      value: formatDateTo24HrTime(moment.utc(selectedTimeSlot.start_time)),
    },
    end_time: {
      label: "End Time",
      value: formatDateTo24HrTime(moment.utc(selectedTimeSlot.end_time)),
    },
    nameOfEvent: { label: "Event name", value: eventDetails.nameOfEvent.value },
    eventType: {
      label: "Event type",
      value: eventDetails.eventType.options.inputProps.options.find(
        (option) => option.value == eventDetails.eventType.value
      ).label,
    },
    companyName: { label: "Company name", value: eventDetails.companyName.value },
    numberOfPeople: {
      label: "Number of attendees",
      value: eventDetails.numberOfPeople.value,
    },
    cost: { label: "Cost", value: formatCurrency("GBP", selectedTimeSlot.cost) },
    specialRequests: {
      label: "Special requests",
      value: additionalRequirements.specialRequests.value,
    },
  };
};

export const buildBookingConfirmationUrl = ({
  eventDetails,
  selectedTimeSlot,
  selectedRoom,
  additionalRequirements,
}) => {
  // NM: Data to json and Base64 encoding
  const bookingConfirmationString = encodeURIComponent(
    btoa(
      JSON.stringify(
        buildBookingConfirmation({
          eventDetails,
          selectedTimeSlot,
          selectedRoom,
          additionalRequirements,
        })
      )
    )
  );

  return window.location.href + `?bookingConfirmationString=${bookingConfirmationString}`;
};
