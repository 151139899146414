import React from "react";
import { Box, TextField, Typography, MenuItem, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { keys } from "lodash";
import MultiTypeInput from "components/MultiTypeInput";

const useStyles = makeStyles((theme) => ({
  containerHeading: {
    marginBottom: theme.spacing(2),
  },
}));

const EventDetails = ({ eventDetails, handleEventDetailsChange }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.containerHeading} variant="subtitle1" color="textPrimary">
        About your event
      </Typography>
      {keys(eventDetails).map((key) => (
        <Box key={eventDetails[key].name} style={{ marginBottom: "20px" }}>
          <MultiTypeInput
            name={eventDetails[key].name}
            label={eventDetails[key].label}
            isRequired={eventDetails[key].isRequired}
            type={eventDetails[key]?.type}
            inputProps={eventDetails[key]?.inputProps}
            value={eventDetails[key].value}
            inputLabelProps={eventDetails[key]?.inputLabelProps}
            error={eventDetails[key]?.error}
            onChange={(evt) => {
              handleEventDetailsChange(eventDetails[key].name, evt.target.value);
            }}
          />
        </Box>
      ))}
    </>
  );
};

EventDetails.propTypes = {
  eventDetails: PropTypes.object,
  handleEventDetailsChange: PropTypes.func,
};

export default EventDetails;
