import moment from "moment";

export const getOptions = (eventFormField, date) => {
  const isToday = date.isSame(moment(), "day");

  var options = null;
  if (isToday) options = eventFormField.todayOptions;
  if (options == null) options = eventFormField.options;

  return options;
};
