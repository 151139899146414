import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import clsx from "clsx";
import { formatCurrency } from "../../utils/helpers";

const useStyle = makeStyles((theme) => {
  return {
    payButton: {
      background: theme.palette.primary.main,
      width: "100%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "20px",
      gap: theme.spacing(1),
      border: "none",
      cursor: "pointer",
    },
    disabled: {
      background: theme.palette.primary.dark,
      cursor: "not-allowed",
    },
    lockIcon: {
      color: theme.palette.primary.contrastText,
    },
    payNowText: {
      color: theme.palette.primary.contrastText,
    },
  };
});

const PayButton = (props) => {
  const classes = useStyle();

  return (
    <button
      className={clsx(classes.payButton, { [classes.disabled]: props.disabled })}
      disabled={props.disabled}
      id="submit">
      <LockOutlined className={classes.lockIcon} />
      <Typography className={classes.payNowText} family="button">
        {`Pay ${formatCurrency("GBP", props.cost)}`}
      </Typography>
    </button>
  );
};

PayButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  cost: PropTypes.number,
};

export default PayButton;
