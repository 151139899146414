import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import compose from "lodash/fp/compose";
import merge from "lodash/fp/merge";

import Routes from "config/routes";
import { dateToStr, today, tomorrow, formatDateShort } from "utils/timekeep";
import {
  formatQuantity,
  getChooseRoomQueryString,
  getCommonSearchParams,
  isKioskFromSearchParams,
  isSingleRoomModeFromSearchParams,
} from "utils/helpers";
import { validStayDates } from "utils/validations";

import api from "utils/api";
import { useSignInWithFirebaseToken } from "hooks";
import Navbar from "components/Navbar";
import RoomPicker from "components/RoomPicker";
import StayDatesActionSheet from "components/StayDatesActionSheet";
import StayDatesPicker from "components/StayDatesPicker";
import ActionSheet from "components/ActionSheet";
import {
  changeAdultsInRoom,
  changeChildrenInRoom,
  setRoomsOccupancy,
  decrementRoomsOccupancy,
  incrementRoomsOccupancy,
  resetBooking,
  setCommonSearchParams,
} from "features/booking/bookingSlice";
import { resetDiningBookings } from "features/dining/diningSlice";
import { setSelectedPropertyId } from "features/property/propertySlice";
import { selectCommonSearchParams } from "selectors/booking";
import { useSearchParams } from "hooks";

import videoPosterImage from "images/plan_stay_poster.jpg";
import planStayVideo from "images/plan_stay_video.mp4";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import PersonIcon from "@material-ui/icons/Person";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Popover,
  Select,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    flexGrow: 1,
    zIndex: 1000,
  },
  textField: {
    minWidth: "120px",
    [theme.breakpoints.up("desktop")]: {
      maxWidth: "180px",
    },
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
      width: "100%",
    },
  },
  fieldIcon: {
    marginRight: "16px",
    marginTop: "12px",
    color: theme.palette.text.primary,
  },
  planStayRoot: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  availCheckerForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexGrow: 1,
    height: "100%",
    [theme.breakpoints.up("desktop")]: {
      flexDirection: "row",
    },
    background: theme.palette.background.default,
  },
  availCheckerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100%",
    minHeight: "max-content",
    width: "100vw",
    margin: "0",
    [theme.breakpoints.up("desktop")]: {
      alignItems: "flex-start",
      flexDirection: "row",
      padding: "25px 45px",
    },
  },
  submitButton: {
    [theme.breakpoints.up("tablet")]: {
      marginLeft: "35px",
    },
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
      margin: "30px 0",
    },
    marginTop: 0,
    marginBottom: 0,
  },
  fieldContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    marginRight: "10px",
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
      justifyContent: "center",
      marginTop: "20px",
      width: "90%",
    },
  },
  promoCodeFieldContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
      width: "100%",
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  inputContainer: {
    width: "100%",
  },
  promoCodeContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
      width: "90%",
    },
  },
  togglePromocodeButton: {
    marginTop: "6px",
    marginLeft: "50px",
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
      marginLeft: "40px",
    },
    color: theme.palette.text.red,
  },
  planStayVideo: {
    objectFit: "cover",
    backgroundSize: "cover",
    maxWidth: "100%",
  },
  mobileVideo: {
    flexGrow: 1,
  },
  desktopVideo: {
    maxHeight: "100%",
    height: "calc(100% - 200px)", // Take off the header height
  },
  kioskVideo: {
    maxHeight: "100%",
    height: "calc(100% - 124px)", // Take off the header height
  },
  datePopover: {
    padding: "5px 30px",
  },
}));

const PlanStay = (props) => {
  const theme = useTheme();
  const desktopUpScreen = useMediaQuery(theme.breakpoints.up("desktop"));
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const properties = useSelector((state) => state.property.properties);
  const selectedPropertyId = useSelector((state) => state.property.selectedPropertyId);
  const roomsOccupancy = useSelector((state) => state.booking.roomsOccupancy);
  const searchParams = useSearchParams();
  useSignInWithFirebaseToken(searchParams.fbauthtoken);

  const { arrival, departure } = useSelector(selectCommonSearchParams);

  // Check availability button disabled until ready
  const [loaded, setLoaded] = useState(false);

  const [checkInDate, setCheckInDate] = useState(
    arrival && validStayDates(arrival, departure) ? moment(arrival).toDate() : today()
  );
  const [checkOutDate, setCheckOutDate] = useState(
    departure && validStayDates(arrival, departure) ? moment(departure).toDate() : tomorrow()
  );

  // Flag to toggle between promo code and corporate code
  const [showCorporateCode, setShowCorporateCode] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [corporateCode, setCorporateCode] = useState("");

  const [roomsPickerAnchorEl, setRoomsPickerAnchorEl] = useState(null);
  const roomsPickerOpen = Boolean(roomsPickerAnchorEl);
  const roomsPickerId = roomsPickerOpen ? "room-picker-popover" : undefined;

  const [datePopoverAnchorEl, setDatePopoverAnchorEl] = useState(null);
  const datePopoverOpen = Boolean(datePopoverAnchorEl);
  const datePopoverId = datePopoverOpen ? "date-picker-popover" : undefined;

  const [calendarDrawerOpen, setCalendarDrawerOpen] = useState(false);
  const [roomsDrawerOpen, setRoomsDrawerOpen] = useState(false);

  const [pricingIndexResp, setPricingIndexResp] = useState(null);

  const handleRoomsPickerOpen = (e) => {
    tabletUpScreen ? setRoomsPickerAnchorEl(e.currentTarget) : setRoomsDrawerOpen(true);
  };
  const handleRoomsPickerClose = () => {
    tabletUpScreen ? setRoomsPickerAnchorEl(null) : setRoomsDrawerOpen(false);
  };

  const handleDatePickerOpen = (e) => {
    tabletUpScreen ? setDatePopoverAnchorEl(e.currentTarget) : setCalendarDrawerOpen(true);

    // Initially, fetch from current selected date to the end of the month
    const fromDate = moment(checkInDate).startOf("month");
    const toDate = fromDate.clone().endOf("month");

    // Pass true to reset the index as this is a new property
    fetchPricingIndex(selectedPropertyId, fromDate, toDate, true);
  };
  const handleDatePickerClose = () => {
    tabletUpScreen ? setDatePopoverAnchorEl(null) : setCalendarDrawerOpen(false);

    // Make sure we always have a check out date
    if (!checkOutDate) {
      setCheckOutDate(moment(checkInDate).add(1, "day").toDate());
    }
  };

  const handleCalendarDatesChanged = (checkInDate, checkOutDate) => {
    setCheckInDate(checkInDate);
    setCheckOutDate(checkOutDate);

    setCalendarDrawerOpen(false);
  };

  const isSameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getDate() === d2.getDate() &&
      d1.getMonth() === d2.getMonth()
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isSameDay(checkInDate, checkOutDate)) {
      props.onNotifOpen(
        "You must stay at least one night to reserve. Please select a date range greater than one day.",
        { variant: "error" }
      );
    } else {
      const availabilitySearchParams = {
        propertyId: selectedPropertyId,
        arrival: dateToStr(checkInDate),
        departure: dateToStr(checkOutDate),
        promoCode,
        corporateCode,
      };

      const generatedSearchParams = getChooseRoomQueryString(
        availabilitySearchParams,
        roomsOccupancy,
        0
      );

      dispatch(resetBooking());
      dispatch(resetDiningBookings());
      dispatch(
        compose(
          setCommonSearchParams,
          getCommonSearchParams,
          merge(searchParams)
        )(availabilitySearchParams)
      );

      props.history.push({
        pathname: Routes.CHOOSE_ROOM,
        search: generatedSearchParams,
      });
    }
  };

  const handlePropertyChange = (event) => {
    dispatch(setSelectedPropertyId(event.target.value));

    updateStayDatesForProperty(event.target.value);
  };

  const handlePromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  };

  const handleCorporateCodeChange = (event) => {
    setCorporateCode(event.target.value);
  };

  const formattedDates = () => {
    const checkInDateStr = checkInDate ? formatDateShort(checkInDate) : "";
    const checkOutDateStr = checkOutDate ? formatDateShort(checkOutDate) : "";

    return `${checkInDateStr} - ${checkOutDateStr}`;
  };

  const roomsAndGuestsTotal = (roomsOccupancy) => {
    const totalAdults = roomsOccupancy.reduce((sum, room) => {
      return sum + parseInt(room.adults);
    }, 0);
    const totalChildren = roomsOccupancy.reduce((sum, room) => {
      return sum + parseInt(room.children);
    }, 0);
    const totalGuests = parseInt(totalAdults) + parseInt(totalChildren);

    if (isSingleRoomModeFromSearchParams(searchParams)) {
      // e.g 2 Guests
      return formatQuantity(totalGuests, "Guest");
    } else {
      // e.g 2 Rooms, 4 Guests
      const totalRooms = roomsOccupancy.length;
      return `${formatQuantity(totalRooms, "Room")}, ${formatQuantity(totalGuests, "Guest")}`;
    }
  };

  const handleRoomsStepperAddClick = () => {
    dispatch(incrementRoomsOccupancy());
  };

  const handleRoomsStepperMinusClick = () => {
    dispatch(decrementRoomsOccupancy());
  };

  const handleAdultsChange = (stepperName, count) => {
    dispatch(
      changeAdultsInRoom({
        roomIndex: stepperName.split("-")[1],
        count,
      })
    );
  };

  const handleChildrenChange = (stepperName, count) => {
    dispatch(
      changeChildrenInRoom({
        roomIndex: stepperName.split("-")[1],
        count,
      })
    );
  };

  const getAllowFromDate = (propertyCode = selectedPropertyId) => {
    const selectedProperty = properties.find((p) => p.code === propertyCode);

    if (!selectedProperty) {
      return moment();
    }

    const settings = selectedProperty.booking_flow_settings;
    if (settings && settings.allow_bookings_from) {
      return moment(settings.allow_bookings_from);
    }

    return moment();
  };

  const updateStayDatesForProperty = (propertyId) => {
    // Update check in and out dates if no longer in range
    // Pass the new property code here as state doesnt immediately update
    const minDate = getAllowFromDate(propertyId);
    if (moment(checkInDate) < minDate) {
      setCheckInDate(minDate.toDate());
      setCheckOutDate(minDate.add(1, "day").toDate());
    }
  };

  const getLowestPriceForDate = (date) => {
    // Dont show if we havent loaded the pricing index
    if (!pricingIndexResp) {
      return null;
    }

    // Dont show a price in the past, or if outside the
    // date range in the fetched pricing index
    const minDate = moment.max(getAllowFromDate(), moment());
    const fromDate = moment(pricingIndexResp.from);
    const toDate = moment(pricingIndexResp.to);

    if (
      date.isBefore(minDate, "day") ||
      date.isBefore(fromDate, "day") ||
      date.isAfter(toDate, "day")
    ) {
      return null;
    }

    // Get the pricing information for the selected date
    const priceInfo = pricingIndexResp.lowest_rates.find(
      (r) => r.date === date.format("YYYY-MM-DD")
    );

    if (priceInfo && priceInfo.price.amount > 0) {
      return priceInfo.price;
    } else {
      // No price info, and within range, so return null
      return null;
    }
  };

  // Date restrictions come from the pricing index endpoint
  const getIsDateRestricted = (date) => {
    // Dont show if we havent loaded the pricing index
    if (!pricingIndexResp) {
      return false;
    }

    // Dont show a restriction in the past, or if outside the
    // date range in the fetched pricing index
    const minDate = moment.max(getAllowFromDate(), moment());
    const fromDate = moment(pricingIndexResp.from);
    const toDate = moment(pricingIndexResp.to);

    if (
      date.isBefore(minDate, "day") ||
      date.isBefore(fromDate, "day") ||
      date.isAfter(toDate, "day")
    ) {
      return false;
    }

    // Return whether this date has a restriction.
    // We dont need to know what that restriction is at this point
    const restricted = (pricingIndexResp?.restricted_dates || []).some(
      (d) => d.date === date.format("YYYY-MM-DD")
    );

    return restricted;
  };

  const fetchPricingIndex = (propertyId, fromDate, toDate, reset = false) => {
    const params = {
      propertyId,
      from: fromDate.format("YYYY-MM-DD"),
      to: toDate.format("YYYY-MM-DD"),
    };

    api
      .getPricingIndex(params)
      .then((pricingInformation) => {
        const newPricingData = pricingInformation.data;

        // If there's already a pricing index, we need to append to it
        // Otherwise, we will create a new index
        if (pricingIndexResp && !reset) {
          const earliestFrom = moment
            .min(moment(pricingIndexResp.from), moment(newPricingData.from))
            .format("YYYY-MM-DD");
          const latestTo = moment
            .max(moment(pricingIndexResp.to), moment(newPricingData.to))
            .format("YYYY-MM-DD");
          const allRates = pricingIndexResp.lowest_rates.concat(newPricingData.lowest_rates);

          const allRestrictedDates = pricingIndexResp.restricted_dates.concat(
            newPricingData.restricted_dates
          );

          setPricingIndexResp({
            ...pricingIndexResp,
            from: earliestFrom,
            to: latestTo,
            lowest_rates: allRates,
            restricted_dates: allRestrictedDates,
          });
        } else {
          setPricingIndexResp(newPricingData);
        }
      })
      .catch((error) => {
        console.log(`Failed to get pricing index: ${error}`);
      });
  };

  const onMonthChanged = (date) => {
    // Check if we have a price for this date - if not, we need to load some more pricing data
    if (getLowestPriceForDate(date) !== null) {
      return;
    }

    // Fetch the index for this month
    const fromDate = date.clone().startOf("month");
    const toDate = date.clone().endOf("month");

    // Dont fetch anything before the allow from date, or after 13 months
    const allowFrom = getAllowFromDate().clone().startOf("month");
    if (fromDate.isBefore(allowFrom) || fromDate.isAfter(moment().add(13, "months"))) {
      return;
    }

    fetchPricingIndex(selectedPropertyId, fromDate, toDate);
  };

  const togglePromoCode = (event) => {
    event.preventDefault();
    setShowCorporateCode(!showCorporateCode);
  };

  const submitOnEnter = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  useEffect(() => {
    // Setup default room occupancy. This is set up here so that you can
    // go diretly to the choose room page & pass in the occupancy there
    dispatch(setRoomsOccupancy([{ adults: 2, children: 0 }]));
  }, []);

  useEffect(() => {
    if (!selectedPropertyId) {
      return;
    }

    // Reset the pricing index as property has changed
    setPricingIndexResp(null);

    // Enable check availability button
    setLoaded(true);
  }, [selectedPropertyId]);

  useEffect(() => {
    if (
      searchParams.propertyId &&
      (!selectedPropertyId || selectedPropertyId !== searchParams.propertyId)
    ) {
      dispatch(setSelectedPropertyId(searchParams.propertyId));
    }
  }, [searchParams.propertyId]);

  useEffect(() => {
    updateStayDatesForProperty(selectedPropertyId);
  }, [properties, selectedPropertyId]);

  return (
    <div className={classes.planStayRoot}>
      {!isKioskFromSearchParams(searchParams) && (
        <Navbar withProfile onNotifOpen={props.onNotifOpen} />
      )}
      <Paper elevation={3} className={classes.formContainer}>
        <form className={classes.availCheckerForm} onSubmit={handleSubmit}>
          {desktopUpScreen ? null : (
            <video
              poster={videoPosterImage}
              autoPlay
              loop
              muted
              playsInline
              className={classNames(classes.planStayVideo, classes.mobileVideo)}>
              <source src={planStayVideo} type="video/mp4" />
            </video>
          )}
          <Grid container className={classes.availCheckerContainer} spacing={2}>
            <Grid className={classes.fieldContainer}>
              <LocationOnIcon className={classes.fieldIcon} />
              <Grid item className={classes.inputContainer}>
                <FormControl fullWidth>
                  <InputLabel shrink>Location</InputLabel>
                  <Select
                    className={classes.textField}
                    value={selectedPropertyId || ""}
                    onChange={handlePropertyChange}>
                    {properties.map((property) => (
                      <MenuItem key={property.code} value={property.code}>
                        {property.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid className={classes.fieldContainer}>
              <DateRangeIcon className={classes.fieldIcon} />
              <Grid item className={classes.inputContainer}>
                {tabletUpScreen ? (
                  <>
                    <FormControl fullWidth>
                      <InputLabel shrink>Dates</InputLabel>
                      <Input
                        id="select-dates"
                        className={classes.inputContainer}
                        value={formattedDates()}
                        onClick={handleDatePickerOpen}
                        inputProps={{
                          readOnly: true,
                          style: { cursor: "pointer" },
                        }}
                      />
                    </FormControl>
                    <Popover
                      id={datePopoverId}
                      open={datePopoverOpen}
                      onClose={handleDatePickerClose}
                      anchorReference="anchorEl"
                      anchorEl={datePopoverAnchorEl}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      PaperProps={{
                        className: classNames(classes.popover, classes.datePopover),
                      }}>
                      <StayDatesPicker
                        checkInDate={checkInDate}
                        checkOutDate={checkOutDate}
                        checkInDateUpdated={setCheckInDate}
                        checkOutDateUpdated={setCheckOutDate}
                        priceForDate={getLowestPriceForDate}
                        isDateRestricted={getIsDateRestricted}
                        onMonthChanged={onMonthChanged}
                        allowDatesFrom={getAllowFromDate()}
                      />
                    </Popover>
                  </>
                ) : (
                  <FormControl fullWidth>
                    <InputLabel shrink>Dates</InputLabel>
                    <Input
                      className={classes.inputContainer}
                      value={formattedDates()}
                      onClick={handleDatePickerOpen}
                      inputProps={{
                        readOnly: true,
                        style: { cursor: "pointer" },
                      }}
                    />
                    <StayDatesActionSheet
                      open={calendarDrawerOpen}
                      onCancel={handleDatePickerClose}
                      onOK={handleCalendarDatesChanged}
                      checkInDate={checkInDate}
                      checkOutDate={checkOutDate}
                      priceForDate={getLowestPriceForDate}
                      isDateRestricted={getIsDateRestricted}
                      onMonthChanged={onMonthChanged}
                      allowDatesFrom={getAllowFromDate()}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
            <Grid className={classes.fieldContainer}>
              <PersonIcon className={classes.fieldIcon} />
              <Grid item className={classes.inputContainer}>
                <FormControl fullWidth>
                  <InputLabel shrink>
                    {isSingleRoomModeFromSearchParams(searchParams) ? "Occupancy" : "Rooms"}
                  </InputLabel>
                  <Input
                    className={classes.inputContainer}
                    value={roomsAndGuestsTotal(roomsOccupancy)}
                    onClick={handleRoomsPickerOpen}
                    inputProps={{
                      readOnly: true,
                      style: { cursor: "pointer" },
                    }}
                  />
                </FormControl>
                {tabletUpScreen ? (
                  <Popover
                    id={roomsPickerId}
                    open={roomsPickerOpen}
                    onClose={handleRoomsPickerClose}
                    anchorReference={"anchorEl"}
                    anchorEl={roomsPickerAnchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}>
                    <RoomPicker
                      roomOccupancies={roomsOccupancy}
                      onAddRoom={handleRoomsStepperAddClick}
                      onRemoveRoom={handleRoomsStepperMinusClick}
                      onAdultsChanged={handleAdultsChange}
                      onChildrenChanged={handleChildrenChange}
                      singleRoom={isSingleRoomModeFromSearchParams(searchParams)}
                    />
                  </Popover>
                ) : (
                  <ActionSheet title="Guests" open={roomsDrawerOpen} onOK={handleRoomsPickerClose}>
                    <RoomPicker
                      roomOccupancies={roomsOccupancy}
                      onAddRoom={handleRoomsStepperAddClick}
                      onRemoveRoom={handleRoomsStepperMinusClick}
                      onAdultsChanged={handleAdultsChange}
                      onChildrenChanged={handleChildrenChange}
                      singleRoom={isSingleRoomModeFromSearchParams(searchParams)}
                    />
                  </ActionSheet>
                )}
              </Grid>
            </Grid>

            <Grid className={classes.promoCodeContainer}>
              <Grid className={classNames(classes.fieldContainer, classes.promoCodeFieldContainer)}>
                <ConfirmationNumberIcon className={classes.fieldIcon} />
                <Grid item className={classes.inputContainer}>
                  <FormControl fullWidth>
                    {showCorporateCode ? (
                      <>
                        <InputLabel shrink>Partner code</InputLabel>
                        <Input
                          className={classes.textField}
                          type="text"
                          value={corporateCode}
                          onChange={handleCorporateCodeChange}
                          onKeyPress={submitOnEnter}
                        />
                      </>
                    ) : (
                      <>
                        <InputLabel shrink>Promo code</InputLabel>
                        <Input
                          className={classes.textField}
                          type="text"
                          value={promoCode}
                          onChange={handlePromoCodeChange}
                          onKeyPress={submitOnEnter}
                        />
                      </>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Link
                component="button"
                variant="body2"
                onClick={togglePromoCode}
                className={classes.togglePromocodeButton}>
                I have a {showCorporateCode ? "promo code" : "partner code"}
              </Link>
            </Grid>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              className={classes.submitButton}
              disabled={!loaded}>
              Check availability
            </Button>
          </Grid>
        </form>
      </Paper>
      {desktopUpScreen ? (
        <video
          poster={videoPosterImage}
          autoPlay
          loop
          muted
          playsInline
          className={classNames(
            classes.planStayVideo,
            isKioskFromSearchParams(searchParams) ? classes.kioskVideo : classes.desktopVideo
          )}>
          <source src={planStayVideo} type="video/mp4" />
        </video>
      ) : null}
    </div>
  );
};

PlanStay.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onNotifOpen: PropTypes.func,
};

export default PlanStay;
