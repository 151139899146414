import { flow, map, get, omit, keyBy, mapValues, capitalize } from "lodash/fp";
import find from "lodash/find";
import { formatDateMedium, formatDateTo24HrTime } from "utils/timekeep";
import moment from "moment";

export const getSelectedRoomData = (roomId, facilityServices) => {
  if (roomId) {
    const selectedRoom = find(facilityServices, { id: roomId });
    return selectedRoom;
  } else return null;
};

export const handleAvailabilitySummary = ({
  eventDetails,
  selectedTimeSlot,
  showPrice = false,
}) => {
  if (selectedTimeSlot) {
    return (
      `${formatDateMedium(eventDetails["arrivalDate"]?.value)}, ${formatDateTo24HrTime(
        moment.utc(selectedTimeSlot.start_time)
      )} -
    ${formatDateTo24HrTime(moment.utc(selectedTimeSlot.end_time))}${
        eventDetails["numberOfHours"]?.value ? ` (${eventDetails["numberOfHours"]?.value}hrs)` : ""
      }, ${eventDetails["numberOfPeople"]?.value} guests` +
      (showPrice ? `${"\u00A0"}|${"\u00A0"} £${selectedTimeSlot.cost}` : "")
    );
  } else {
    const date = formatDateMedium(eventDetails["arrivalDate"]?.value);
    const time = eventDetails["arrivalTime"]?.value
      ? capitalize(eventDetails["arrivalTime"]?.value)
      : null;
    const people = eventDetails["numberOfPeople"]?.value
      ? `${eventDetails["numberOfPeople"]?.value} guests`
      : null;

    return [date, time, people].filter((sentence) => sentence != null).join(", ");
  }
};

export const CHECK_AVAILABILITY_STEP_INDEX = 0;
export const EVENT_DETAILS_STEP_INDEX = 1;
export const YOUR_DETAILS_STEP_INDEX = 2;
export const PAYMENT_DETAILS_STEP_INDEX = 3;
