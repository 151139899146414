export const KIOSK = "kiosk";

// Special addon service code used to associate ev charging to a service
export const EVPARKING_SERVICE_CODE = "EV_CHARGING";
export const EVPARKING_UNITGROUP_CODE = "PARKING";

export const STEPS = {
  PLAN_STAY: {
    INDEX: 1,
    STEPPER_TITLE: "Dates & guests",
    PAGE_TITLE: "Stay at Mollie's",
  },
  CHOOSE_ROOMS: {
    INDEX: 2,
    STEPPER_TITLE: "Choose rooms",
    PAGE_TITLE: "Choose your room",
  },
  CUSTOMIZE_STAY: {
    INDEX: 3,
    STEPPER_TITLE: "Customise stay",
    PAGE_TITLE: "Customise your stay",
  },
  PAYMENT: {
    INDEX: 4,
    STEPPER_TITLE: "Payment",
    PAGE_TITLE: "Payment",
  },
};

export const PRIVATE_SPACES = {
  PRIVATE_DINING: {
    PAGE_TITLE: "Private Dining",
  },
};

// Hardcoded for now as mollies dont use sevenrooms shifts to control this
// Start and end times are inclusive
export const BREAKFAST_START_TIME = "06:00";
export const BREAKFAST_END_TIME = "11:00";

export const DINNER_START_TIME = "16:00";
export const DINNER_END_TIME = "22:00";

// Reservation addon types
export const ADDON_TYPE_REGULAR = "addons.regular-addon-details";
export const ADDON_TYPE_BASKET = "addons.basket-addon-details";
export const ADDON_TYPE_DATE = "addons.date-addon-details";
